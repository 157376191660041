const californiaProviders = [
  'Pacific Gas & Electric',
  'Southern California Edison',
  'San Diego Gas & Electric',
  'Los Angeles Department of Water and Power',
  '--------------------------',
];

const floridaProviders = [
  'Florida Power & Light',
  'Duke Energy',
  'TECO',
  'JEA',
  '--------------------------',
];

const newYorkProviders = [
  'National Grid',
  'Consolidated Edison Company of New York (Con Edison)',
  'New York State Electric & Gas (NYSEG)',
  'Long Island Power Authority (LIPA)',
  '--------------------------',
];

const texasProviders = [
  'TXU Energy',
  'Reliant Energy',
  'CPS Energy',
  'Entergy',
  '--------------------------',
];

const pennsylvaniaProviders = [
  'PPL',
  'PECO',
  'Met-Ed',
  'Penelec',
  '--------------------------',
];

const arizonaProviders = [
  'Arizona Public Service',
  'Salt River Project',
  'Tucson Electric Power',
  'UniSource Energy Services',
  '--------------------------',
];

const shadeField = [
  {
    label: 'Full Sun',
    value: 'No Shade',
  },
  {
    label: 'Partial Sun',
    value: 'A Little Shade',
  },
  {
    label: 'No Sun',
    value: 'A Lot of Shade',
  },
  {
    label: 'Uncertain',
    value: 'Uncertain',
  },
];

const projectTypeWindows = [
  {
    label: 'Replace',
    value: 'Replace Unit',
  },
  {
    label: 'Repair',
    value: 'Window Repair',
  },
];

const windowCount = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3-5',
    value: '3-5',
  },
  {
    label: '6-9',
    value: '6-9',
  },
  {
    label: '10+',
    value: '10+',
  },
];

const projectTimeFrame = [
  {
    label: 'Flexible',
    value: 'Timing Flexible',
  },
  {
    label: '1-2 Weeks',
    value: '1-2 Weeks',
  },
  {
    label: '2 Weeks +',
    value: 'More than 2 Weeks',
  },
];

export {
  texasProviders,
  newYorkProviders,
  pennsylvaniaProviders,
  floridaProviders,
  californiaProviders,
  arizonaProviders,
  shadeField,
  projectTypeWindows,
  windowCount,
  projectTimeFrame,
};
