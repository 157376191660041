const californiaProviders = [
  { label: 'Pacific Gas & Electric', value: 'Pacific Gas & Electric' },
  { label: 'Southern California Edison', value: 'Southern California Edison' },
  { label: 'San Diego Gas & Electric', value: 'San Diego Gas & Electric' },
  {
    label: 'LA Department of Water and Power',
    value: 'Los Angeles Department of Water and Power',
  },
  { label: 'Other', value: 'ShowDropDown' },
];

const floridaProviders = [
  { label: 'Florida Power & Light', value: 'Florida Power & Light' },
  { label: 'Duke Energy', value: 'Duke Energy' },
  { label: 'TECO', value: 'TECO' },
  { label: 'JEA', value: 'JEA' },
  { label: 'Other', value: 'ShowDropDown' },
];

const newYorkProviders = [
  { label: 'National Grid', value: 'National Grid' },
  {
    label: 'Consolidated Edison Co. of NY',
    value: 'Consolidated Edison Company of New York (Con Edison)',
  },
  {
    label: 'New York State Electric & Gas (NYSEG)',
    value: 'New York State Electric & Gas (NYSEG)',
  },
  {
    label: 'Long Island Power Authority (LIPA)',
    value: 'Long Island Power Authority (LIPA)',
  },
  { label: 'Other', value: 'ShowDropDown' },
];

const texasProviders = [
  { label: 'TXU Energy', value: 'TXU Energy' },
  { label: 'Reliant Energy', value: 'Reliant Energy' },
  { label: 'CPS Energy', value: 'CPS Energy' },
  { label: 'Entergy', value: 'Entergy' },
  { label: 'Other', value: 'ShowDropDown' },
];

const pennsylvaniaProviders = [
  { label: 'PPL', value: 'PPL' },
  { label: 'PECO', value: 'PECO' },
  { label: 'Met-Ed', value: 'Met-Ed' },
  { label: 'Penelec', value: 'Penelec' },
  { label: 'Other', value: 'ShowDropDown' },
];

const arizonaProviders = [
  { label: 'Arizona Public Service', value: 'Arizona Public Service' },
  { label: 'Salt River Project', value: 'Salt River Project' },
  { label: 'Tucson Electric Power', value: 'Tucson Electric Power' },
  { label: 'UniSource Energy Services', value: 'UniSource Energy Services' },
  { label: 'Other', value: 'ShowDropDown' },
];

const georgiaProviders = [
  { label: 'Georgia Power', value: 'Georgia Power' },
  { label: 'Other', value: 'ShowDropDown' },
];

const northCarolinaProviders = [
  { label: 'Duke Energy', value: 'Duke Energy' },
  { label: 'Other', value: 'ShowDropDown' },
];

const massachusettsProviders = [
  { label: 'National Grid', value: 'National Grid' },
  { label: 'Eversource Energy', value: 'Eversource Energy' },
  { label: 'Massachusetts Electric', value: 'Massachusetts Electric' },
  { label: 'Other', value: 'ShowDropDown' },
];

const ohioProviders = [
  { label: 'American Electric Power', value: 'American Electric Power' },
  { label: 'Duke Energy Ohio', value: 'Duke Energy Ohio' },
  { label: 'Ohio Edison', value: 'Ohio Edison' },
  { label: 'Dayton Power & Light', value: 'Dayton Power & Light' },
  { label: 'Other', value: 'ShowDropDown' },
];

const newJerseyProviders = [
  {
    label: 'Public Service Electric & Gas',
    value: 'Public Service Electric and Gas Company (PSE&G)',
  },
  {
    label: 'Jersey Central Power & Light',
    value: 'Jersey Central Power and Light Company',
  },
  { label: 'Atlantic City Electric', value: 'Atlantic City Electric' },
  { label: 'Other', value: 'ShowDropDown' },
];

const marylandProviders = [
  { label: 'Baltimore Gas & Electric', value: 'Baltimore Gas & Electric' },
  { label: 'PEPCO', value: 'PEPCO' },
  { label: 'Other', value: 'ShowDropDown' },
];

const coloradoProviders = [
  { label: 'Xcel Energy', value: 'Xcel Energy' },
  { label: 'Colorado Springs Utilities', value: 'Colorado Springs Utilities' },
  { label: 'Other', value: 'ShowDropDown' },
];

const louisianaProviders = [
  { label: 'Entergy', value: 'Entergy' },
  { label: 'CLECO', value: 'CLECO' },
  { label: 'Other', value: 'ShowDropDown' },
];

const alabamaProviders = [
  { label: 'Alabama Power', value: 'Alabama Power' },
  { label: 'Other', value: 'ShowDropDown' },
];

const dcProviders = [
  { label: 'PEPCO', value: 'PEPCO' },
  { label: 'Other', value: 'ShowDropDown' },
];

const idahoProviders = [
  { label: 'Idaho Power', value: 'Idaho Power' },
  { label: 'Other', value: 'ShowDropDown' },
];

const illinoisProviders = [
  { label: 'ComEd Electric Company', value: 'ComEd Electric Company' },
  { label: 'Ameren', value: 'Ameren' },
  { label: 'Other', value: 'ShowDropDown' },
];

const arkansasProviders = [
  { label: 'Entergy', value: 'Entergy' },
  { label: 'First Electric Cooperative', value: 'First Electric Cooperative' },
  { label: 'Other', value: 'ShowDropDown' },
];

const connecticutProviders = [
  { label: 'Eversource Energy', value: 'Eversource Energy' },
  { label: 'Connecticut Light & Power', value: 'Connecticut Light & Power' },
  { label: 'Other', value: 'ShowDropDown' },
];

const delawareProviders = [
  { label: 'Delmarva Power', value: 'Delmarva Power' },
  {
    label: 'Delaware Electric Cooperative',
    value: 'Delaware Electric Cooperative',
  },
  { label: 'Other', value: 'ShowDropDown' },
];

const iowaProviders = [
  { label: 'Alliant Energy', value: 'Alliant Energy' },
  {
    label: 'MidAmerican Energy',
    value: 'MidAmerican Energy',
  },
  { label: 'Other', value: 'ShowDropDown' },
];

const alaskaProviders = [
  {
    label: 'Chugach Electric Association',
    value: 'Chugach Electric Association',
  },
  {
    label: 'Matanuska Electric Association',
    value: 'Matanuska Electric Association',
  },
  {
    label: 'Golden Valley Electric Association',
    value: 'Golden Valley Electric Association',
  },
  { label: 'Other', value: 'ShowDropDown' },
];

const hawaiiProviders = [
  {
    label: 'Hawaiian Electric Company (HECO)',
    value: 'Hawaiian Electric Company (HECO)',
  },
  {
    label: 'Hawaii Electric Light',
    value: 'Hawaii Electric Light',
  },
  {
    label: 'Maui Electric Company',
    value: 'Maui Electric Company',
  },
  {
    label: 'Kauai Island Utility Cooperative (KIUC)',
    value: 'Kauai Island Utility Cooperative (KIUC)',
  },
  { label: 'Other', value: 'ShowDropDown' },
];

const indianaProviders = [
  {
    label: 'Duke Energy Indiana',
    value: 'Duke Energy Indiana',
  },
  {
    label: 'Northern Indiana Public Service Company',
    value: 'Northern Indiana Public Service Company',
  },
  {
    label: 'Indiana Michigan Power',
    value: 'Indiana Michigan Power',
  },
  {
    label: 'American Electric Power',
    value: 'American Electric Power',
  },
  { label: 'Other', value: 'ShowDropDown' },
];

const kansasProviders = [
  { label: 'Every', value: 'Every' },
  { label: 'Other', value: 'ShowDropDown' },
];

const kentuckyProviders = [
  { label: 'Louisville Gas & Electric', value: 'Louisville Gas & Electric' },
  { label: 'American Electric Power', value: 'American Electric Power' },
  { label: 'Other', value: 'ShowDropDown' },
];

const maineProviders = [
  { label: 'Central Maine Power', value: 'Central Maine Power' },
  { label: 'Bangor Hydro Electric', value: 'Bangor Hydro Electric' },
  { label: 'Other', value: 'ShowDropDown' },
];

const michiganProviders = [
  {
    label: 'DTE Energy (Detroit Edison)',
    value: 'DTE Energy (Detroit Edison)',
  },
  { label: 'Consumers Energy', value: 'Consumers Energy' },
  { label: 'Other', value: 'ShowDropDown' },
];

const minnesotaProviders = [
  {
    label: 'Xcel Energy',
    value: 'Xcel Energy',
  },
  { label: 'Minnesota Power', value: 'Minnesota Power' },
  { label: 'Other', value: 'ShowDropDown' },
];

const mississippiProviders = [
  {
    label: 'Entergy',
    value: 'Entergy',
  },
  { label: 'Mississippi Power Company', value: 'Mississippi Power Company' },
  { label: 'Other', value: 'ShowDropDown' },
];

const missouriProviders = [
  {
    label: 'Ameren',
    value: 'Ameren',
  },
  { label: 'Other', value: 'ShowDropDown' },
];

const montanaProviders = [
  {
    label: 'Flathead Electric Co-Op',
    value: 'Flathead Electric Co-Op',
  },
  { label: 'Other', value: 'ShowDropDown' },
];

const nebraskaProviders = [
  {
    label: 'Omaha Public Power District',
    value: 'Omaha Public Power District',
  },
  {
    label: 'Nebraska Public Power District',
    value: 'Nebraska Public Power District',
  },
  { label: 'Other', value: 'ShowDropDown' },
];

const nevadaProviders = [
  {
    label: 'NV Energy',
    value: 'NV Energy',
  },
  {
    label: 'Nevada Power',
    value: 'Nevada Power',
  },
  { label: 'Other', value: 'ShowDropDown' },
];

const newHampshireProviders = [
  { label: 'Eversource Energy', value: 'Eversource Energy' },
  { label: 'Other', value: 'ShowDropDown' },
];

const newMexicoProviders = [
  {
    label: 'Public Service Company of New Mexico',
    value: 'Public Service Company of New Mexico',
  },
  { label: 'New Mexico Gas Company', value: 'New Mexico Gas Company' },
  { label: 'Other', value: 'ShowDropDown' },
];

const northDakotaProviders = [
  { label: 'Otter Tail Power Company', value: 'Otter Tail Power Company' },
  { label: 'Xcel Energy', value: 'Xcel Energy' },
  { label: 'Other', value: 'ShowDropDown' },
];

const oklahomaProviders = [
  { label: 'Oklahoma Gas & Electric', value: 'Oklahoma Gas & Electric' },
  {
    label: 'Public Service Company of Oklahoma',
    value: 'Public Service Company of Oklahoma',
  },
  { label: 'Other', value: 'ShowDropDown' },
];

const oregonProviders = [
  { label: 'PacifiCorp (Pacific Power)', value: 'PacifiCorp (Pacific Power)' },
  { label: 'Other', value: 'ShowDropDown' },
];

const rhodeIslandProviders = [
  { label: 'National Grid', value: 'National Grid' },
  { label: 'Other', value: 'ShowDropDown' },
];

const southCarolinaProviders = [
  { label: 'Duke Energy SC', value: 'Duke Energy SC' },
  { label: 'Dominion Energy', value: 'Dominion Energy' },
  { label: 'Other', value: 'ShowDropDown' },
];

const southDakotaProviders = [
  { label: 'Xcel Energy', value: 'Xcel Energy' },
  { label: 'Black Hills Power', value: 'Black Hills Power' },
  { label: 'Other', value: 'ShowDropDown' },
];

const tennesseeProviders = [
  {
    label: 'Memphis Light Gas and Water',
    value: 'Memphis Light Gas and Water',
  },
  { label: 'Nashville Electric Service', value: 'Nashville Electric Service' },
  { label: 'Other', value: 'ShowDropDown' },
];

const utahProviders = [
  {
    label: 'PacifiCorp (Rocky Mountain Power)',
    value: 'PacifiCorp (Rocky Mountain Power)',
  },
  { label: 'Utah Power & Light', value: 'Utah Power & Light' },
  { label: 'Other', value: 'ShowDropDown' },
];

const vermontProviders = [
  {
    label: 'Vermont Electric Cooperative',
    value: 'Vermont Electric Cooperative',
  },
  { label: 'Other', value: 'ShowDropDown' },
];

const virginiaProviders = [
  {
    label: 'Dominion Virginia Power',
    value: 'Dominion Virginia Power',
  },
  {
    label: 'Appalachian Power',
    value: 'Appalachian Power',
  },
  { label: 'Other', value: 'ShowDropDown' },
];

const washingtonProviders = [
  {
    label: 'Puget Sound Energy',
    value: 'Puget Sound Energy',
  },
  {
    label: 'Avista Utilities',
    value: 'Avista Utilities',
  },
  {
    label: 'Snohomish County Public Utility District (PUD)',
    value: 'Snohomish County Public Utility District (PUD)',
  },
  { label: 'Other', value: 'ShowDropDown' },
];

const westVirginiaProviders = [
  { label: 'Allegheny Power', value: 'Allegheny Power' },
  { label: 'Other', value: 'ShowDropDown' },
];

const wisconsinProviders = [
  {
    label: 'Wisconsin Public Service Corporation',
    value: 'Wisconsin Public Service Corporation',
  },
  { label: 'Xcel Energy', value: 'Xcel Energy' },
  { label: 'Other', value: 'ShowDropDown' },
];

const wyomingProviders = [
  {
    label: 'PacifiCorp (Rocky Mountain Power)',
    value: 'PacifiCorp (Rocky Mountain Power)',
  },
  { label: 'Other', value: 'ShowDropDown' },
];

export {
  wyomingProviders,
  wisconsinProviders,
  westVirginiaProviders,
  washingtonProviders,
  virginiaProviders,
  vermontProviders,
  utahProviders,
  tennesseeProviders,
  southDakotaProviders,
  southCarolinaProviders,
  rhodeIslandProviders,
  oregonProviders,
  oklahomaProviders,
  northDakotaProviders,
  newMexicoProviders,
  newHampshireProviders,
  nevadaProviders,
  nebraskaProviders,
  montanaProviders,
  mississippiProviders,
  missouriProviders,
  minnesotaProviders,
  michiganProviders,
  maineProviders,
  kansasProviders,
  kentuckyProviders,
  iowaProviders,
  indianaProviders,
  illinoisProviders,
  idahoProviders,
  hawaiiProviders,
  dcProviders,
  delawareProviders,
  connecticutProviders,
  arkansasProviders,
  alaskaProviders,
  alabamaProviders,
  texasProviders,
  newYorkProviders,
  floridaProviders,
  californiaProviders,
  pennsylvaniaProviders,
  arizonaProviders,
  georgiaProviders,
  northCarolinaProviders,
  massachusettsProviders,
  ohioProviders,
  newJerseyProviders,
  marylandProviders,
  coloradoProviders,
  louisianaProviders,
};
