/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const AdDisclosureContainer = styled.div`
  p {
    margin-bottom: 10px;
  }
`;
const StyledHeader = styled.h2`
  color: #0257a2;
  font-size: clamp(22px, 3vw, 40px);
`;

const BoldUnderline = styled.p`
  font-weight: 700;
  text-decoration: underline;
  margin-top: 20px;
  &.lg {
    font-size: 1.1em;
  }
`;

function GetAdDisclosure() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [disclosure, setDisclosure] = useState([]);
  useEffect(() => {
    fetch(
      'https://s3.amazonaws.com/ratemarketplace.com/media.ratemarketplace.com/data/AdDisclosure.json'
    )
      .then(res => res.json())
      .then(
        result => {
          setDisclosure(result);
          setTimeout(() => {
            setIsLoaded(true);
          }, 250);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <AdDisclosureContainer>
        <StyledHeader>{disclosure.title}</StyledHeader>
        <p>{disclosure.intro}</p>
        <BoldUnderline className="lg">
          Disclosure current as of {disclosure.discDate}.
        </BoldUnderline>

        <div>
          {disclosure.rates.map(rate => (
            <p key={rate.id}>
              <strong>{rate.rate}</strong> {rate.APRdesc}
            </p>
          ))}
        </div>
        <div>
          {disclosure.postRatesMessage.map(disclaimer => (
            <p key={disclaimer.id}>{disclaimer.disclaimer}</p>
          ))}
        </div>
        <BoldUnderline>
          15 Year Fixed Rate as low as {disclosure.rates[0].rate} Disclosure
          current as of {disclosure.discDate}.
        </BoldUnderline>
        <div>
          {disclosure.disclaimers15Year.map(disclaimer => (
            <p key={disclaimer.id}>{disclaimer.disclaimer}</p>
          ))}
        </div>
        <div>
          <BoldUnderline>
            30-year Fixed Rate as low as 3.125% (3.250% APR) – Disclosure
            current as of {disclosure.discDate}.
          </BoldUnderline>
        </div>
        <div>
          {disclosure.disclaimers30Year.map(disclaimer => (
            <p key={disclaimer.id}>{disclaimer.disclaimer}</p>
          ))}
        </div>
      </AdDisclosureContainer>
    );
  }
}

export default GetAdDisclosure;
