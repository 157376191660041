import React from 'react';
import { Field, ErrorMessage } from 'formik';
import styled from 'styled-components';
import { projectTypeWindows } from '../../../components/inputs/Dropdown/upsellValues';
import { webHookTrack } from '../../dynamic/webhookTrack';
import { g4EventTracking } from '../../dynamic/g4Tracking';

const ChoiceContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
  box-shadow: 2px 2px 4px #ababab;
  border: 1px solid #194687;
  border-radius: 5px;
  color: #194687;
  width: 100%;
  padding: 20px 0;
  font-weight: 600;
  &:hover {
    background: #194687;
    color: #fff;
  }
  p {
    text-align: center;
    margin-bottom: 0;
  }
`;
const RadioField = styled(Field)`
  display: none;
`;

const StyledErrorMsg = styled(ErrorMessage)`
  color: #ee0000;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  p {
    width: 100%;
    font-weight: 600;
    font-size: 20px;
    // margin-bottom: 0;
  }
`;

const InputContainer = styled.div`
  width: 31%;
  margin: 10px 0;
  > input:checked + label {
    background: #194687;
    color: #fff;
  }
  p {
    font-size: 16px;
  }
  @media (max-width: 801px) {
    width: 45%;
  }
`;

const UpsellProjectType = ({ setDynamicText, config }) => {
  const handleDynamicClick = value => {
    if (value === 'Window Repair') {
      setDynamicText('repairing');
    } else {
      setDynamicText('replacing');
    }

    webHookTrack('upsell', { ProjectType: value });

    g4EventTracking('upsell', { ProjectType: value });
  };
  return (
    <QuestionContainer>
      <p>Would you like to replace or repair your windows?</p>
      {projectTypeWindows.map((option, key) => (
        <InputContainer id={`ProjectType-${key}`} key={key}>
          <RadioField
            component="input"
            type="radio"
            name="ProjectType"
            value={option.value}
            id={option.value}
          />
          <ChoiceContainer
            htmlFor={option.value}
            id={`ProjectType-${key}`}
            name="ProjectType"
            className={option.label}
            onClick={() => handleDynamicClick(option.value)}
          >
            <p>{option.label}</p>
          </ChoiceContainer>
        </InputContainer>
      ))}
      <StyledErrorMsg name="shade" component="div" className="styled-error" />
    </QuestionContainer>
  );
};

export default UpsellProjectType;
