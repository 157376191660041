import React from 'react';
import styled from 'styled-components';

const CredibleDisclosureContainer = styled.p`
  color: rgb(118, 118, 118);
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  text-align: center;
  padding: 10px 5px;
  .srOnly {
    flex: 0 0 50px;
    margin-top: auto;
    margin-bottom: 10px;
  }
`;

const CredibleDisclosure = () => {
  return (
    <CredibleDisclosureContainer className="maCredibleDisclosure">
      <span className="srOnly">Form successfully submitted.</span> We accept
      advertising compensation from companies that appear on the site, which
      impacts the location and order in which brands (and/or their products) are
      presented.
    </CredibleDisclosureContainer>
  );
};

export default CredibleDisclosure;
