import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { InputField } from '../Email';
import { numbersOnly } from '../../../utils/Validation/numbersOnly';
import styles from '../../../utils/customStyles';
import { areaCodes } from './areaCodes';

const customStyles = styles();

const StyledInput = styled(InputField)`
  border: ${props => props.border};
`;

const StyledPhoneInput = styled(InputField)``;

const InputContainer = styled.div`
  position: relative;
  width: 300px;
  margin-bottom: 20px;
`;

const ErrorText = styled.p`
  margin-bottom: 0;
  ${customStyles.ErrorText}
`;

const PhoneInput = ({
  option,
  config,
  currentValue,
  setFieldValue,
  errors,
  type,
  handleOnBlur = () => {},
  setErrors = () => {},
}) => {
  const [didErr, setError] = useState(false);
  const [finalError, setFinalError] = useState('');

  useEffect(() => {
    if (errors.phone_primary) {
      setFinalError('Please complete this field.');
    }
  }, [errors]); // eslint-disable-line

  useEffect(() => {
    if (currentValue && !currentValue.includes('(')) {
      const displayNumber = `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )} ${currentValue.slice(6)}`;
      setFieldValue('phone_primary', displayNumber);
      // numbersOnly();
    }
    // eslint-disable-next-line
  }, []);

  const phoneAreaCheck = val => {
    let isValid = false;
    const valCheck = val?.replace(/\D/g, '').substr(0, 3);
    areaCodes.forEach(e => {
      if (e === valCheck) {
        isValid = true;
      }
    });
    return isValid;
  };
  const numberPlaceholder = () => {
    let returnString = '';
    if (config.phonePlaceholder_test) {
      returnString = `Phone Number (XXX-XXX-XXXX)`;
    } else if (config.noEnterOnPlaceholder_test) {
      returnString = 'Phone Number';
    } else {
      returnString = 'Enter Phone Number';
    }
    return returnString;
  };
  const validatePhone = value => {
    let error;
    setError(false);

    const validAreaCode = phoneAreaCheck(value);
    if (value === '' || !value) {
      setError(true);
      error = 'Please enter a phone number';
    } else if (value && !validAreaCode) {
      setError(true);
      error = 'Please enter a valid area code';
      // setFieldValue('phone_primary', '');
      setFinalError(error);
    } else if (value.length < 14) {
      setError(true);
      error = 'Please complete this field.';
      setFinalError(error);
      // wrote this small regex: https://regex101.com/r/45G2OS/1
    } else if (!/\d{3}\D*\d{3}\D*\d{4}/.test(value)) {
      setError(true);
      error = 'Please complete this field.';
      setFinalError(error);
    } else {
      setFinalError(false);
      setError(false);
      setErrors({});
      error = '';
    }

    return error;
  };

  const setPhoneDisplay = e => {
    const { value } = e.target;
    if (value.length >= 5) {
      const phoneCheck = phoneAreaCheck(value);
      if (!phoneCheck) {
        setError(true);
        validatePhone(value);
      } else {
        setError(false);
        validatePhone(value);
      }
      // 10 digits + parentheses(2) + spaces between groupings(2)
    } else if (value.length === 14) {
      setError(false);
    } else {
      validatePhone(value);
    }

    handleOnBlur();
  };

  return (
    <Fragment>
      {config.LabelAsPlaceholder ? (
        <InputContainer>
          <StyledPhoneInput
            type="tel"
            id="phone-input"
            name={(option && option.label) || 'phone_primary'}
            validate={validatePhone}
            border={didErr ? 'true' : null}
            value={currentValue}
            onBlur={setPhoneDisplay}
            onInput={numbersOnly}
            onKeyUp={e => validatePhone(e.target.value)}
            data-testid="phone"
            maxLength="14"
            required
          />
          <label htmlFor="phone-input" className="placeholderLabel">
            Phone Number
          </label>
          {type === 'final-frame' && finalError && (
            <ErrorText role="alert">{finalError}</ErrorText>
          )}
        </InputContainer>
      ) : (
        <Fragment>
          <StyledInput
            type="tel"
            id="phone-input"
            name={(option && option.label) || 'phone_primary'}
            validate={validatePhone}
            placeholder={numberPlaceholder()}
            border={didErr ? 'true' : null}
            value={currentValue}
            onBlur={setPhoneDisplay}
            onInput={numbersOnly}
            onKeyUp={e => validatePhone(e.target.value)}
            data-testid="phone"
            maxLength="14"
            aria-label="Phone Number"
          />
          {type === 'final-frame' && finalError && (
            <ErrorText role="alert">{finalError}</ErrorText>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

PhoneInput.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  config: PropTypes.object,
  currentValue: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
};

export default PhoneInput;
