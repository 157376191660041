import React from 'react';
import PhoneInput from '../../../components/inputs/Phone/PhoneInput';

const UpsellPhoneInput = ({ config, setFieldValue, values, errors }) => {
  return (
    <div className="question">
      <p className="solar-label">Your telephone number:</p>
      <PhoneInput
        option={{
          label: 'PhoneNumber',
          value: 'Phone Number',
        }}
        config={config}
        setFieldValue={setFieldValue}
        currentValue={values.PhoneNumber}
        errors={errors}
        type="final-frame"
        pattern="[0-9]+"
      />
    </div>
  );
};

export default UpsellPhoneInput;
