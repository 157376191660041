// function inactiveUser() {
//   console.log('user inactive for 5 seconds');
// }

let timer;
const resetTimer = setIsInactive => {
  clearTimeout(timer);
  timer = setTimeout(() => {
    // inactiveUser();
    setIsInactive(true);
  }, 20000);
};

function activateActivityTracker(setIsInactive) {
  window.addEventListener('mousemove', () => {
    resetTimer(setIsInactive);
  });
  window.addEventListener('scroll', () => {
    resetTimer(setIsInactive);
  });
  window.addEventListener('keydown', () => {
    resetTimer(setIsInactive);
  });
  window.addEventListener('resize', () => {
    resetTimer(setIsInactive);
  });
}

export { activateActivityTracker };
