import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AdButler from './AdButler';
import AdButlerTest from './AdbutlerTest';
import storage from '../../utils/storage';
import tyPageSlackAlert from './TyPageSlackAlert';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  min-height: 100vh;
  width: 100%;
  max-width: 1200px;
  font-family: sans-serif;
  background: #ffffff;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const DynamicThankYou = ({
  adButlerIds,
  style,
  tyType,
  adButlerAcctId,
  typTest,
  params,
  config,
  path,
  isILX = false,
}) => {
  useEffect(() => {
    storage('session', 'set', 'typTimeLoaded', new Date().getTime());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    storage('session', 'set', 'abZones', JSON.stringify(adButlerIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adButlerIds]);

  return (
    <Container style={style}>
      {adButlerIds.length && typTest ? (
        <AdButlerTest
          adButlerIds={adButlerIds}
          adButlerAcctId={adButlerAcctId}
          tyType={tyType}
          path={path}
          config={config}
        />
      ) : (
        <>
          {!isILX && (
            <AdButler
              adButlerIds={adButlerIds}
              adButlerAcctId={adButlerAcctId}
              path={path}
              config={config}
            />
          )}
        </>
      )}
    </Container>
  );
};

DynamicThankYou.propTypes = {
  adButlerIds: PropTypes.array,
  adButlerAcctId: PropTypes.string,
  style: PropTypes.object,
  config: PropTypes.object,
  params: PropTypes.object,
  tyType: PropTypes.string,
  typTest: PropTypes.bool,
  path: PropTypes.string,
};

export default DynamicThankYou;
