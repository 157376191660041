/* eslint-disable no-else-return */
/* eslint-disable no-unused-expressions */
import React from 'react';
import styled, { keyframes } from 'styled-components';

const TcpaContainer = styled.div`
  min-height: 20px;
  max-width: 800px;
  margin: 0 auto 10px auto;
  background: transparent;
  color: #767676;
  font-size: 12px;
  line-height: 14px;
  @media (max-width: 801px) {
    padding: 0 10px;
  }
`;

const UpsellTcpa = ({ upsellType }) => {
  if (upsellType === 'homeservices') {
    return (
      <TcpaContainer className="tcpa">
        <div id="tcpa" className="tcpa-container">
          <div className="privacy_rights well" id="srDisclosure">
            <input id="leadid_tcpa_disclosure" type="hidden" />{' '}
            <label htmlFor="leadid_tcpa_disclosure">
              By clicking the button and submitting a form, you are providing
              your electronic signature in which you consent, acknowledge and
              agree to the following: (a) our{' '}
              <a href="/rmp-tos.html" target="_blank">
                Terms of Service
              </a>{' '}
              and{' '}
              <a href="/rmp-privacy.html" target="_blank">
                Privacy Policy
              </a>
              , including our arbitration provision. (b) You are providing
              express written consent to share your information with
              RateMarketplace, and up to{' '}
              <a href="/ServicePartners.html" target="_blank">
                4 home service and solar professionals
              </a>{' '}
              or authorized third parties calling on our, or their behalf who
              may contact you about their products and services. (c) You give
              consent (consent not required as a condition to purchase a
              good/service) for them/us to contact you through automated means,
              for marketing purposes at the phone number(s) and at the email
              address you have provided (e.g. automatic telephone dialing
              system, text, artificial or pre-recorded messaging) via telephone,
              mobile device (including SMS and MMS) and/or email, even if your
              telephone number is currently listed on any state, federal or
              corporate Do Not Call registry. You may opt-out from SMS or Text
              messages at any time by replying STOP. Data and Msg rates may
              apply. As an alternative, you may contact us by email at:{' '}
              <a href="mailto:customercare@ratemarketplace.com">
                customercare@ratemarketplace.com
              </a>
              . You understand that you may revoke this consent at any time.
            </label>
          </div>
        </div>
      </TcpaContainer>
    );
  } else if (upsellType === 'insurance') {
    return (
      <TcpaContainer>
        <div className="privacy_rights well" id="srDisclosure">
          <input id="leadid_tcpa_disclosure" type="hidden" />{' '}
          <label htmlFor="leadid_tcpa_disclosure">
            By clicking the "See My Results" button above, you are providing
            your electronic signature in which you consent, acknowledge and
            agree to the following: (a) our{' '}
            <a href="/rmp-tos.html" target="_blank">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="/rmp-privacy.html" target="_blank">
              Privacy Policy
            </a>
            , including our arbitration provision. (b) You are providing express
            written consent to share your information with RateMarketplace, and
            up to{' '}
            <a href="/ServicePartners.html" target="_blank">
              5 Industry Partners
            </a>
            , or authorized third parties calling on our, or their behalf who
            may contact you for marketing purposes. (c) You give consent
            (consent not required as a condition to purchase a good/service) for
            them/us to contact you through automated means at the phone
            number(s) and at the email address you have provided (e.g. automatic
            telephone dialing system, text, artificial or pre-recorded
            messaging) via telephone, mobile device (including SMS and MMS)
            and/or email, even if your telephone number is currently listed on
            any state, federal or corporate Do Not Call registry. You may
            opt-out from SMS or Text messages at any time by replying STOP. Data
            and Msg rates may apply. As an alternative, you may contact us by
            email at:{' '}
            <a href="mailto:customercare@ratemarketplace.com">
              customercare@ratemarketplace.com
            </a>
            . You understand that you may revoke this consent at any time.
          </label>
        </div>
      </TcpaContainer>
    );
  } else {
    <TcpaContainer className="tcpa">
      <div id="tcpa" className="tcpa-container">
        <div className="privacy_rights well" id="srDisclosure">
          <input id="leadid_tcpa_disclosure" type="hidden" />{' '}
          <label htmlFor="leadid_tcpa_disclosure">
            By clicking the button and submitting a form, you are providing your
            electronic signature in which you consent, acknowledge and agree to
            the following: (a) our{' '}
            <a href="/rmp-tos.html" target="_blank">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="/rmp-privacy.html" target="_blank">
              Privacy Policy
            </a>
            , including our arbitration provision. (b) You are providing express
            written consent to share your information with RateMarketplace, and
            up to{' '}
            <a href="/ServicePartners.html" target="_blank">
              4 home service and solar professionals
            </a>{' '}
            or authorized third parties calling on our, or their behalf who may
            contact you about their products and services. (c) You give consent
            (consent not required as a condition to purchase a good/service) for
            them/us to contact you through automated means, for marketing
            purposes at the phone number(s) and at the email address you have
            provided (e.g. automatic telephone dialing system, text, artificial
            or pre-recorded messaging) via telephone, mobile device (including
            SMS and MMS) and/or email, even if your telephone number is
            currently listed on any state, federal or corporate Do Not Call
            registry. You may opt-out from SMS or Text messages at any time by
            replying STOP. Data and Msg rates may apply. As an alternative, you
            may contact us by email at:{' '}
            <a href="mailto:customercare@ratemarketplace.com">
              customercare@ratemarketplace.com
            </a>
            . You understand that you may revoke this consent at any time.
          </label>
        </div>
      </div>
    </TcpaContainer>;
  }
};

export default UpsellTcpa;
