import { parse } from 'query-string';
import storage from '../storage';

const gtmLoad = async (config, isTYPGTM) => {
  const {
    gtmContainer,
    scData,
    offerId,
    surveyType,
    isSCLabs,
    affiliateTestingBan,
  } = config;
  const query = parse(window.location.search);
  let skipTest = false;
  const siteUrl = document.URL;
  let blockedAffiliateTesting = ['1127'];
  if (affiliateTestingBan) {
    blockedAffiliateTesting = ['1127', ...affiliateTestingBan];
  }

  if (query?.nt === '1') {
    skipTest = true;
  }

  const parsed = {};
  Object.keys(query).forEach(key => {
    parsed[key.toLowerCase()] = query[key];
  });

  const checkUrlForSiteName = sites => {
    if (sites.some(v => siteUrl.includes(v))) {
      return true;
    }
    return false;
  };

  // Hack to get around the gtm call
  window.getParameterByName = (name, url) => {
    let newUrl;
    if (!url) {
      newUrl = window.location.href;
    }
    const newName = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${newName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(newUrl);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  // Google Tag Manager
  setTimeout(() => {
    if (config) {
      window.scData = [
        {
          siteVertical: scData.siteVertical,
          siteName: scData.siteName,
          siteNameV2: 'v2',
          defaultOffer: offerId,
          siteCompany: 'Parent',
          siteBuyer: 'Combined',
        },
      ];
    } else {
      // in case the user's storage is turned off or full
      window.scData = [
        {
          siteVertical: 'other',
          siteName: window.location.hostname,
          siteNameV2: 'v2',
          defaultOffer: parsed.o || 52,
          siteCompany: 'Parent',
          siteBuyer: 'Combined',
        },
      ];
    }
    storage('session', 'set', 'scData', JSON.stringify(window.scData));

    // use this to store various items for use where you can't access the config object (TYPs)
    // setting globalConfig here gives us isSCLabs on back button sites
    storage(
      'session',
      'set',
      'globalConfig',
      JSON.stringify({ surveyType, isSCLabs })
    );

    let gtmContainerID = gtmContainer || 'GTM-P62KC9K';

    // n14 sites
    const n14Sites = ['costcuttingsolar.com'];
    if (checkUrlForSiteName(n14Sites) && isTYPGTM && query.a === '397') {
      gtmContainerID = 'GTM-N4ZD4NB';
    }

    // created for a single site and affiliate but if we do another let's refactor to loop through an object of site/affitlate/gtm containers
    if (
      checkUrlForSiteName(['mylifeinsurancesurvey.com']) &&
      query.a === '1694'
    ) {
      // Google Tag Manager script
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? `&l=${l}` : '';
        j.async = true;
        // j.crossorigin = 'anonymous';
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        if (f) {
          f.parentNode.insertBefore(j, f);
        }
      })(window, document, 'script', 'scData', 'GTM-N923HPG');
    }

    // Google Tag Manager script
    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l !== 'dataLayer' ? `&l=${l}` : '';
      j.async = true;
      // j.crossorigin = 'anonymous';
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      if (f) {
        f.parentNode.insertBefore(j, f);
      }
    })(window, document, 'script', 'scData', gtmContainerID);
  }, 100);
};

export default gtmLoad;
