const checkMobile = query => {
  const mediaQueryList =
    typeof window !== 'undefined' &&
    window.matchMedia &&
    window.matchMedia(query);

  if (mediaQueryList && mediaQueryList.matches) {
    return true;
  }
  return false;
};

export default checkMobile;
