import { backButtonTest } from './adButlerTests';

const medSuppZonesPerSite = ({ a }, { surveyName }) => {
  // zone 579095 is for med headline and phone test
  switch (surveyName) {
    case 'med.insurancespecialists.com':
      if (a === '2141') {
        return ['582616', '574211', '578475'];
      }
      return ['582616', '574211', '578472'];
    case 'med4.insurancespecialists.com':
      return ['582616', '444950', '578475'];
    case 'med2.ratemarketplace.com':
    case 'med.ratemarketplace.com':
      return ['582616', '574211', '578476'];
    default:
      return ['582616', '520343', '574211', '427126', '427128', '427129'];
  }
};

const adbutlerZones = (page, SC_LABS_TY_PAGE, params, config) => {
  const headlineBackTestZone = backButtonTest(params, config);
  // if (SC_LABS_TY_PAGE) {
  //   switch (page) {
  //     case 'ty':
  //     case 'tel-thankyou':
  //     case 'usl-thankyou':
  //     case 'thankyouBCR':
  //     case 'thankyouBCP':
  //     case 'thankyouGCP':
  //     case 'thankyouGCR':
  //     case 'shi-thankyou':
  //       // prettier-ignore
  //       return {
  //         PurchaseBadCredit: ['468796', '468797', '468798', '468799', '468800'], // BCP
  //         PurchaseGoodCredit: ['468806', '468807', '468808', '468809', '468810'], // GCP
  //         RefinanceBadCredit: ['468801', '468802', '468803', '468804', '468805'], // BCR
  //         RefinanceGoodCredit: ['468779', '468777', '468778', '468842', '468776'], // GCR
  //         SolarBadCredit: ['482637', '482636', '482638', '482639', '482640'], // BCS
  //         SolarGoodCredit: ['468816', '468817', '468818', '468819', '468820'], // GCS
  //         BathroomBadCredit: ['565947', '565948', '565949', '565950'], // BCBR --- Home Service
  //         BathroomGoodCredit: ['565947', '565948', '565949', '565950'], // GCBR --- Home Service
  //         RoofingBadCredit: ['468811', '468812', '468813', '468814', '468815'], // BCRoof --- Home Service
  //         RoofingGoodCredit: ['468811', '468812', '468813', '468814', '468815'], // GCRoof --- Home Service
  //         WindowsBadCredit: ['468821', '468822', '468823', '468824', '468825'], // BCW --- Home Service
  //         WindowsGoodCredit: ['468821', '468822', '468823', '468824', '468825'], // GCW --- Home Service
  //         AutoBadCredit: ['468826', '468827', '468828', '468829', '468830'], // BCA
  //         AutoGoodCredit: ['468826', '468827', '468828', '468829', '468830'], // GCA
  //         MedGoodCredit: ['468843', '468844', '468845', '468846', '468848'], // GCM
  //         MedBadCredit: ['468843', '468844', '468845', '468846', '468848'], // BCM
  //         HomeGoodCredit: ['478101', '478102', '478103', '478104', '478105'], // GCH --- Home Owners Insurance (HOI)
  //         HomeBadCredit: ['478101', '478102', '478103', '478104', '478105'], // BCH --- Home Owners Insurance (HOI)
  //         HealthGoodCredit: ['468831', '468832', '468833', '468834', '468835'], // GCH
  //         HealthBadCredit: ['468831', '468832', '468833', '468834', '468835'], // BCH
  //         LifeGoodCredit: ['468836', '468837', '468838', '468839', '468840'], // GCL --- Life Insurance
  //         LifeBadCredit: ['468836', '468837', '468838', '468839', '468840'], // BCL --- Life Insurance
  //         default: ['468779', '468777', '468778', '468842', '468776'],
  //       }
  //     case 'rates':
  //       return {
  //         hs_solar: ['469676', '469677', '469678', '469679', '469680'],
  //         hs_roofing: ['469671', '469672', '469673', '469674', '469675'],
  //         hs_window: ['469681', '469682', '469683', '469684', '469685'],
  //         hs_br: ['565951', '565952', '565953', '565954'],
  //         default: ['468779', '468777', '468778', '468842', '468776'],
  //       };
  //     case 'lb':
  //       return {
  //         default: ['468779', '468777', '468778', '468842', '468776'],
  //       };
  //     case 'back':
  //       // prettier-ignore
  //       return {
  //           auto: ['470851', '470852', '470853', '470854', '470855'],
  //           solar: ['470861', '470862', '470863', '470864', '470865'],
  //           br: ['565951', '565952', '565953', '565954'],
  //           roofing: ['470861', '470862', '470863', '470864', '470865'],
  //           window: ['470861', '470862', '470863', '470864', '470865'],
  //           health: ['470856', '470857', '470858', '470859', '470860'],
  //           home: ['470861', '470862', '470863', '470864', '470865'],
  //           life: ['470866', '470867', '470868', '470869', '470870'],
  //           med: ['470871', '470872', '470873', '470874', '470875'],
  //           harp: ['470846', '470847', '470848', '470849', '470850'],
  //           va: ['470846', 'pid470847', '470848', '470849', 'pid470850'],
  //           default: ['468779', '468777', '468778', '468842', '468776'],
  //         };
  //     default:
  //       return {
  //         default: ['468779', '468777', '468778', '468842', '468776'],
  //       };
  //   }
  // } else {
  switch (page) {
    case 'ty':
    case 'thankyouBCR':
    case 'thankyouBCP':
    case 'thankyouGCP':
    case 'thankyouGCR':
      // prettier-ignore
      return {
          PurchaseBadCredit: ['252560', '252581', '252538', '252186', '252187', '252188', '252189', '252190'], // BCP 
          PurchaseGoodCredit: ['252584', '252585', '252539', '252540', '252541', '252542', '252196', '252197'], // GCP
          RefinanceBadCredit: ['252587', '252588', '252552', '252191', '252192', '252193', '252194', '252195', '368789'], // BCR
          RefinanceGoodCredit: ['252590', '252591', '252551', '231259', '231260', '231261', '252198', '252199', '255380', '255381', '315227'], // GCR
          SolarBadCredit: ['365653', '365654', '365655', '365656', '365657', '428389'], // BCS
          SolarGoodCredit: ['365653', '365654', '365655', '365656', '365657', '428389'], // GCS
          BathroomBadCredit: ['565947', '565948', '565949', '565950'], // BCBR --- Home Service
          BathroomGoodCredit: ['565947', '565948', '565949', '565950'], // GCBR --- Home Service
          RoofingBadCredit: ['467458', '467459', '467460', '467461', '467462'], // BCRoof --- Home Service
          RoofingGoodCredit: ['467458', '467459', '467460', '467461', '467462'], // GCRoof --- Home Service
          AlarmBadCredit: ['417903', '417904', '417905', '417906', '417907'], // BCH --- Home Service
          AlarmGoodCredit: ['417903', '417904', '417905', '417906', '417907'], // GCH --- Home Service
          WindowsBadCredit: ['467453', '467454', '467455', '467456', '467457'], // BCW --- Home Service
          WindowsGoodCredit: ['467453', '467454', '467455', '467456', '467457'], // GCW --- Home Service
          FinanceBadCredit: ['252560', '252581', '252538', '252186', '252187', '252188', '252189', '252190'],
          FinanceGoodCredit: ['252584', '252585', '252539', '252540', '252541', '252542', '252196', '252197'],
          AutoBadCredit: ['281150', '571857'], // BCA
          AutoGoodCredit: ['281150', '571857'], // GCA
          MedGoodCredit: medSuppZonesPerSite(params, config), // GCM
          MedBadCredit: medSuppZonesPerSite(params, config), // BCM
          HealthGoodCredit: ['444950', '444949', '444951', '444952', '444953'], // GCH
          HealthBadCredit: ['444950', '444949', '444951', '444952', '444953'], // BCH
          HomeGoodCredit: ['424232', '424233', '424234', '424235', '424236'], // GCH --- Home Owners Insurance (HOI)
          HomeBadCredit: ['424232', '424233', '424234', '424235', '424236'], // BCH --- Home Owners Insurance (HOI)
          LifeGoodCredit: ['432863', '432864', '432865', '432866', '432867', '432868'], // GCL --- Life Insurance
          LifeBadCredit: ['432863', '432864', '432865', '432866', '432867', '432868'], // BCL --- Life Insurance
          AutoVariation1: ['281150', '570461'], // A/B Test Variation 1 for AUTO TYPs
          SolarOwnerVariation1: ['473601', '473602', '473603', '473604', '473605'], // A/B Test for Solar Owner
          BadCreditPurchaseVariation1: ['473606', '473607', '473608', '473609', '473610', '473611'], // A/B Test for BCP
          LifeVariation1: ['432863', '432864', '432865', '432866', '432867', '432868'], // A/B Test for Life
          HomeVariation1: ['473618', '473619', '473620', '473621', '473622'], // A/B Test for Home
          GCRVariation1: ['483827', '483828', '483829', '483830', '483831', '483832', '483833', '483834'],
          BCRVariation1: ['483809', '483810', '483811', '483812', '483813', '483814', '483815', '483816'],
          GCPVariation1: ['483817', '483818', '483819', '483820', '483821', '483822', '483823', '483824'],
          IdeaClanZones: ['554588', '554589'],
          A1968LifeZones: ['558248', '558249'],
          A1968MedZones: ['564198', '564199'],
          MAbackUp: ['574211','578475'],
          simpleHealth: ['444950', '586054'],
          medSimpleHealth: ['574211', '588891'],
          simpleHealthPro: ['444950', '586272'],
          medFourHealth: ['444950', '585950'],
          healthinsurance: ['444950', '588892'],
          HealthMA: ['444950', '582180'],
          autoMA: ['281150', '595384'],
          default: ['252584', '252585', '252539', '252540', '252541', '252542', '252196', '252197'],
        }
    case 'rates':
      return {
        hs_solar: ['365658', '365659', '365660', '365661', '365662'],
        hs_br: ['565951', '565952', '565953', '565954'],
        hs_roofing: ['552905', '552906', '552907', '552908', '552909'],
        hs_window: ['417908', '417909', '417910', '417911', '417912'],
        hs_hvac: ['417908', '417909', '417910', '417911', '417912'],
        ins_home: ['424237', '424238', '424239', '424240', '424241'],
        default: ['417908', '417909', '417910', '417911', '417912'],
      };
    case 'lb':
      return {
        'downpaymentsurvey.com': ['472592', '472593'],
        'enhanced-relief.com': ['472594', '472595'],
        default: ['417908', '417909', '417910', '417911', '417912'],
      };
    case 'shi-thankyou':
      // prettier-ignore
      return {
            SolarBadCredit: ['365653', '365654', '365655', '365656', '365657', '428389'], // BCS
            SolarGoodCredit: ['365653', '365654', '365655', '365656', '365657', '428389'], // GCS
            default: ['365653', '365654', '365655', '365656', '365657', '428389'],
          };
    case 'tel-thankyou':
      // prettier-ignore
      return {
          default: ['483835', '483836', '483837', '483838', '483839', '483840', '483841', '483842']
        }
    case 'back':
      // prettier-ignore
      return {
          auto: ['420783', '420784', '420785', '420786', '420787'],
          health:  ['467194', '467195', '467196', '467197', '467198'],
          home: ['467317', '467318', '467319', '467320', '467321'],
          life: ['467322', '467323', '467324', '467325', '467326'],
          med:['467327', '467328', '467329', '467330', '467331'],
          solar: ['493424', '493425', '493426', '493427', '493428'],
          br: ['565951', '565952', '565953', '565954'],
          roofing: ['493429', '493430', '493431', '493432', '493433'],
          window: ['493434', '493435', '493436', '493437', '493438'] ,
          default: [headlineBackTestZone, '256622', '256623', '256624'], // add '256619', '256620', '256621', back in after test
        };
    default:
      return {
        default: [
          '252584',
          '252585',
          '252539',
          '252540',
          '252541',
          '252542',
          '252196',
          '252197',
        ],
      };
  }
  // }
};

export default adbutlerZones;
