import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'formik';
import styles from '../../utils/customStyles';
import Checkbox from '../Checkbox';

const customStyles = styles();

export const InputField = styled(Field)`
  height: 40px;
  ${customStyles.InputField}
`;

const CheckboxWrapper = styled.section`
  ${customStyles.CheckboxWrapper}
`;

const LPContainer = styled.div`
  position: relative;
  width: 100%;
`;

const ErrorText = styled.p`
  ${customStyles.ErrorText}
`;

const EmailContainer = styled.div`
  align-items: center;
  width: 100%;
  ${customStyles.AddressContainer};
  ${customStyles.EmailContainer};
`;

const Email = ({
  name,
  index,
  params,
  config,
  values,
  type,
  setErrors,
  setFieldValue = () => {},
  handleOnBlur = () => {},
}) => {
  const [didErr, setError] = useState('');

  const emailPeriodCheck = value => {
    const multiplePeriodsTest = /^(?!.*?\.\.).*?$/;
    let extraPeriods = false;
    if (!multiplePeriodsTest.test(value)) {
      extraPeriods = true;
    } else {
      extraPeriods = false;
    }
    return extraPeriods;
  };

  // checks if email is valid.
  // from Formik docs: https://jaredpalmer.com/formik/docs/guides/validation
  const validateEmail = value => {
    let error;
    setError('');
    if (value === '') {
      error = 'Please enter an email address.';
    } else if (
      !value ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ) {
      error = 'Please enter a valid email address';
    } else if (emailPeriodCheck(value)) {
      error = 'Please enter a valid email address';
    } else if (value.length >= 256) {
      error = 'Please enter a valid email address';
    }
    setError(error);
    if (value) {
      setFieldValue('email', value);
    }
    return error;
  };

  const emailOnly = element => {
    const { value } = element.target;

    if (value.includes('@') && !emailPeriodCheck(value)) {
      const checkEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        value
      );
      if (checkEmail) {
        setError('');
        setErrors({});
      }
    }
  };

  return (
    <EmailContainer id="email-container">
      {type !== 'final-frame' && (
        <p className="emailLabel">
          {config.emailLabel ? `${config.emailLabel}` : `Email Address*:`}
        </p>
      )}
      {config.LabelAsPlaceholder ? (
        <LPContainer>
          <InputField
            name={name}
            value={values?.email}
            id={name}
            validate={validateEmail}
            onKeyUp={e => validateEmail(e.target.value)}
            onInput={emailOnly}
            data-testid="email"
            type="email"
            border={didErr ? 'true' : null}
            autoFocus={!index}
            onBlur={handleOnBlur}
            autoComplete={
              navigator.userAgent.match(/FBAV/i) ||
              navigator.userAgent.match(/FBAN/i) ||
              navigator.userAgent.match(/FB_IAB/i)
                ? 'off'
                : 'email'
            }
            required
          />
          <label className="placeholderLabel" htmlFor={name}>
            Email
          </label>
        </LPContainer>
      ) : (
        <InputField
          name={name}
          validate={validateEmail}
          value={values?.email}
          onKeyUp={e => validateEmail(e.target.value)}
          onInput={emailOnly}
          data-testid="email"
          type="email"
          placeholder={`${
            config.noEnterOnPlaceholder_test ? '' : 'Enter'
          } Email Address`}
          border={didErr ? 'true' : null}
          autoFocus={!index}
          onBlur={handleOnBlur}
          aria-label="Email"
          autoComplete={
            navigator.userAgent.match(/FBAV/i) ||
            navigator.userAgent.match(/FBAN/i) ||
            navigator.userAgent.match(/FB_IAB/i)
              ? 'off'
              : 'email'
          }
        />
      )}

      {type !== 'final-frame' && didErr && (
        <ErrorText data-testid="error-message" id="error-message" role="alert">
          {didErr}
        </ErrorText>
      )}
      {config.separateEmail && config.emailDisclaimer && (
        <div id="email-disclaimer" data-testid="email-disclaimer">
          <p>
            I represent that I am 18+ years of age. I agree to receive email
            newsletters and special offers targeted to my interests, sent by
            ratemarketplace.com and their marketing partners.
          </p>
          <p>
            By clicking Next, you are providing your consent: (1) to the{' '}
            <a href="/rmp-tos.html" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="/rmp-privacy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            ; (2) to share your information with RateMarketplace, and with
            lenders with whom you have been matched or other business partners
            who purchase leads from our website to identify potential customers
            who may be interested in their products or services; and (3) for
            RateMarketplace and such lenders or business partners to contact you
            at the email you provided. Please note that the information you have
            provided to us may be supplemented with additional information
            obtained from other sources. As an alternative, you may contact us
            by email at customercare@ratemarketplace.com.
          </p>
        </div>
      )}
    </EmailContainer>
  );
};

Email.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number,
  params: PropTypes.object,
  config: PropTypes.object,
  values: PropTypes.object,
  setErrors: PropTypes.func,
  type: PropTypes.string,
};

export default Email;
