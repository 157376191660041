import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import styles from '../../utils/customStyles';
import { StoreContext } from '../StoreContext';

const customStyles = styles();

const InputContainer = styled.div`
  span {
    font-size: 0.7em;
  }

  ${customStyles.InputContainer}
`;

const ChoiceCircleContainer = styled(BackgroundImage)`
  width: ${props => props.width || '120px'};
  height: ${props => props.width || '120px'};
  ${customStyles.CircleStyles}

  &:before,
  &:after {
    border-radius: 50%;
  }
`;

const ChoiceContainer = styled.label`
  ${customStyles.ChoiceContainer}
  border: ${props => props.border};
  white-space: pre-line;
  /* :focus{
    outline: none;
  } */
`;

const RadioField = styled(Field)`
  display: none;
`;

const Choice = ({
  index,
  option,
  name,
  values,
  length,
  params,
  config,
  didErr = false,
  showCoverageText,
  coverageTypeAltText_test,
  setFieldValue,
  ...props
}) => {
  const { value, label, bgImage, labelHelper } = option;
  const [isChecked, setChecked] = useState('');
  const [backgroundImage, setBackgroundImage] = useState({});
  const [coverageText, setCoverageText] = useState('');

  const { images } = useContext(StoreContext);

  useEffect(() => {
    // HACK -- used to make sure selected/default answer is checked
    let mounted = true;
    setTimeout(() => {
      if (mounted) {
        if (name === 'employer' && values[name].includes('un')) {
          if (length > 2) {
            setChecked(option.value === 'unemployed');
          } else if (values[name].includes('verified')) {
            setChecked(option.value === 'unemployed verified');
          } else {
            setChecked(option.value === 'unemployed');
          }
        } else if (params.nb && params.nb === '1' && params.pp !== '1') {
          setChecked(false);
        } else if (params.hnb === '1') {
          setChecked(false);
        } else if (values[name] !== value) {
          setChecked(false);
        } else if (values[name] === value) {
          setChecked(true);
        } else {
          setChecked(false);
        }
      }
    }, 0);

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [config, length, name, params, value, values]);

  const homeownersValues = ['OwnOrRent', 'own_rent', 'own_home'];

  // allow casing values for pre-pop
  // useEffect(() => {
  //   if (homeownersValues.includes(name)) {
  //     if (
  //       values[name] &&
  //       typeof values[name] === 'string' &&
  //       values[name]?.toLowerCase() === value?.toLowerCase()
  //     ) {
  //       setTimeout(() => {
  //         setChecked(true);
  //       }, 100);
  //     }
  //   }
  // }, [homeownersValues, name, option, value, values]);

  useEffect(() => {
    if (config.choiceImage && value !== 'own') {
      const findFluid = images.find(
        el =>
          (el.node.fluid.originalName.includes(bgImage?.toLowerCase()) ||
            el.node.fluid.originalName.includes(value?.toLowerCase())) &&
          !el.node.fluid.originalName.includes('hover')
      );
      if (findFluid) {
        setBackgroundImage(findFluid.node.fluid);
      } else {
        setBackgroundImage({});
      }
    } else {
      setBackgroundImage({});
    }
  }, [bgImage, config, images, label, value]);

  // For `choiceAlphabet` config option
  const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];

  useEffect(() => {
    if (config.selectMultipleChoiceOptions[0]) {
      const findValue = values[name].includes(value);
      if (findValue) {
        setTimeout(() => {
          setChecked(true);
        }, 100);
      }
    }
  }, [config, name, value, values]);

  useEffect(() => {
    if (value === 'Term') {
      if (config.coverageTypeAltText_test) {
        setCoverageText(
          '(Term life provides coverage at a fixed number of payments for a set period.)'
        );
      } else {
        setCoverageText('(Protect your debt and leave money for loved ones)');
      }
    }
    if (value === 'Whole') {
      setCoverageText('(Guarantee coverage for life)');
    }
    if (value === 'Final Expense') {
      if (config.coverageTypeAltText_test) {
        setCoverageText(
          '(Final Expense coverage covers end of life expenses such as funeral cost.)'
        );
      } else {
        setCoverageText('(Cover end of life expenses such as funeral costs)');
      }
    }
    if (value === 'Not Sure') {
      if (config.coverageTypeAltText_test) {
        setCoverageText(
          '(We can match you with the best providers for your needs.)'
        );
      } else {
        setCoverageText(
          '(Let us connect you with providers suited to your needs)'
        );
      }
    }
  }, [value, config.coverageTypeAltText_test]);

  const handleKeyDown = e => {
    if (e.key === 'Enter' || e.keyCode === 32) {
      e.preventDefault();
      setFieldValue(name, value);
      props.onClick();
    }
  };

  const determineLabel = () => {
    let returnedLabel;

    switch (label) {
      // console.log('🚀 ~ file: Choice.js ~ line 191 ~ label', label);
      case 'Manufactured (Permanent Foundation)':
        if (config.buttonsWithParagraph) {
          returnedLabel = (
            <p>
              Manufactured<span> (Permanent Foundation)</span>
            </p>
          );
        } else {
          returnedLabel = (
            <pre>
              Manufactured<span> (Permanent Foundation)</span>
            </pre>
          );
        }
        break;
      case 'Excellent (720 and above)':
        if (config.buttonsWithParagraph) {
          returnedLabel = (
            <p>
              Excellent <span>(720 and above)</span>
            </p>
          );
        } else {
          returnedLabel = label;
        }
        break;
      case 'Good (660-719)':
        if (config.buttonsWithParagraph) {
          returnedLabel = (
            <p>
              Good <span>(660-719)</span>
            </p>
          );
        } else {
          returnedLabel = label;
        }
        break;
      case 'Average (620-659)':
        if (config.buttonsWithParagraph) {
          returnedLabel = (
            <p>
              Average <span>(620-659)</span>
            </p>
          );
        } else {
          returnedLabel = label;
        }
        break;
      case 'Below Average (580-619)':
        if (config.buttonsWithParagraph) {
          returnedLabel = (
            <p>
              Below Average <span>(580-619)</span>
            </p>
          );
        } else {
          returnedLabel = label;
        }
        break;
      case 'Poor (579 and below)':
        if (config.buttonsWithParagraph) {
          returnedLabel = (
            <p>
              Poor <span>(579 and below)</span>
            </p>
          );
        } else {
          returnedLabel = label;
        }
        break;
      case 'Walk-In Tub (recommended for seniors)':
        returnedLabel = (
          <p>
            Walk-In Tub<span> (recommended for seniors)</span>
          </p>
        );
        break;
      case 'Walk-In Bathtub (recommended for seniors)':
        returnedLabel = (
          <p>
            Walk-In Bathtub<span> (recommended for seniors)</span>
          </p>
        );
        break;
      case "Yes, I own a home. (Select this option if you'd like to take cash out.)":
        returnedLabel = (
          <p>
            Yes, I own a home.
            <span> (Select this option if you'd like to take cash out.)</span>
          </p>
        );
        break;
      default:
        if (config.buttonsWithParagraph) {
          returnedLabel = <p>{label}</p>;
        } else {
          returnedLabel = label;
        }
    }
    return returnedLabel;
  };

  return (
    <InputContainer className={`input-${name}`}>
      <RadioField
        component="input"
        type={
          config.selectMultipleChoiceOptions[0] &&
          config.selectMultipleChoiceOptions[0].includes(name)
            ? 'checkbox'
            : 'radio'
        }
        role="radio"
        id={value}
        data-testid="radio-button"
        name={name}
        {...props}
        checked={isChecked}
      />
      {config.choiceImage && Object.entries(backgroundImage)?.length > 0 ? (
        <ChoiceCircleContainer
          Tag="label"
          role="radio"
          fluid={backgroundImage}
          tabIndex={params.nb === '1' ? '-1' : '0'}
          htmlFor={value}
          name={name}
          // backgroundImage={bgImage || label}
          width={length < 3 ? '200px' : '120px'}
          survey={config.surveyName}
          className={`circle-input-${name} value-${value}`}
          id={`${name}_${value}`}
          onKeyDown={handleKeyDown}
        >
          {label}
        </ChoiceCircleContainer>
      ) : (
        <ChoiceContainer
          tabIndex={params.nb === '1' ? '-1' : '0'}
          htmlFor={value}
          role="radio"
          name={name}
          border={didErr ? true : null}
          id={`${name}_${value}`}
          className={`${label}`}
          onKeyDown={handleKeyDown}
        >
          {config.choiceAlphabet && (
            <p className="alphabet">{alphabet[index]}</p>
          )}

          <>{determineLabel()}</>

          {labelHelper && <span className="labelHelper">{labelHelper}</span>}
        </ChoiceContainer>
      )}
      {showCoverageText && name === 'CoverageType' && (
        <p className="hide">{coverageText}</p>
      )}
    </InputContainer>
  );
};

Choice.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  index: PropTypes.number,
  length: PropTypes.number,
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    labelHelper: PropTypes.string,
    value: PropTypes.string.isRequired,
    bgImage: PropTypes.string,
  }),
  params: PropTypes.object,
  config: PropTypes.object,
  showCoverageText: PropTypes.bool,
};

export default Choice;
