import storage from '../utils/storage';

const formData = storage('session', 'get', 'formData');

let phoneNumber = '';
if (formData) {
  const parsedFormData = JSON.parse(formData);
  if (parsedFormData) {
    const { phone_primary } = parsedFormData;
    phoneNumber = phone_primary;
  }
}

const connectedStream = moduleId => {
  const callTechContainer = document.getElementById('callTech');
  let callTechString;
  if (callTechContainer) {
    callTechString = `<div class="g3cm_holder" data-module="${moduleId}" data-showoninit="true" data-conversionid="" data-destination="" data-destinationext="" data-followup="" style="display:none;">Thank you for your inquiry. A representative will be in contact with you shortly.</div> `;
  }
  callTechContainer.innerHTML = callTechString;
};

const returnedConnectedStreamString = moduleID => {
  const script2 = document.createElement('script');
  script2.innerHTML = ` 
  ${connectedStream(moduleID)};
  window.g3cm =
    window.g3cm ||
    function() {
      (window.g3cm.q = window.g3cm.q || []).push(arguments);
    };
  g3cm('loadjquery', 'true');
  g3cm('phonenumber', '${phoneNumber}');
  g3cm('send', 'init');`;
  document.head.appendChild(script2);

  const script = document.createElement('script');
  script.src = 'https://api.connectstreams.com/js/connectme-v3.min.js';
  script.async = true; // This is required for synchronous execution
  document.head.appendChild(script);
};

export { returnedConnectedStreamString };
