const url = document.URL;
const dynamicSiteConfig = config => {
  const { dynamicSites } = config;
  const isEmpty = dynamicSites[0].dynamicSurveyName === '';

  // requires at least one dynamicSurveyName to exist before running this logic. If not it will overwrite parts of config we don't want overwritten.
  if (!isEmpty) {
    dynamicSites.forEach(obj => {
      if (url.includes(obj.dynamicSurveyName)) {
        Object.entries(obj).forEach(entry => {
          const [key, value] = entry;

          if (value !== null && Array.isArray(value)) {
            config[key] = [...new Set([...config[key], ...value])].filter(
              Boolean
            );
          } else if (value !== null && typeof value === 'object') {
            config[key] = { ...config[key], ...value };
          } else {
            config[key] = value || config[key];
          }
        });
      }
    });
  }
};

export default dynamicSiteConfig;
