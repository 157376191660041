import React from 'react';
import styled, { keyframes } from 'styled-components';
import ModalLinks from '../../../components/Modal/ModalLinks';
import { homeService } from '../../compliance/defaultDisclosures';

const Footer = styled.div`
  padding: 30px 0 10px;
  width: calc(100% - 30px);
  margin: 0 auto 30px;
  h3,
  p,
  h2,
  h4,
  li {
    text-align: left;
  }

  .disclosureHeader {
    font-size: 1.2em;
    text-align: center;
  }

  .disclosureContent {
    font-size: 12px;
  }

  .disclosureContainer {
    max-width: 960px;
  }

  button {
    background: transparent;
    color: #333;
    font-size: 12px;
    height: 0;
    width: initial;
    &:hover {
      background: transparent;
    }
  }

  #xButton {
    height: 33px;
    width: 33px;
    background: rgb(255, 255, 255);
    position: absolute;
    border-radius: 50%;
    border: 1px solid rgb(238, 238, 238);
    font-size: 1.5em;
    transition: all 0.5s ease 0s;
    cursor: pointer;
    padding: 0px;
    top: 7vh;
    right: 13vw;
    &:hover {
      color: #333;
    }
  }
  #RMP_Legal {
    text-align: left;
    p {
      text-align: left;
    }
  }
`;

const UpsellDisclosure = ({ config, params }) => {
  return (
    <Footer>
      <h3 className="disclosureHeader">Important Disclosures</h3>
      <div className="disclosureContainer">
        <p className="disclosureContent">
          This site is a free service to assist homeowners in connecting with
          local service contractors. All contractors are independent and this
          site does not warrant or guarantee any work performed. It is the
          responsibility of the homeowner to verify that the hired contractor
          furnishes the necessary license and insurance required for the work
          being performed. All persons depicted in a photo or video in any
          advertisement associated with this site are actors or models and not
          contractors affiliated with this site.
        </p>
        <p className="disclosureContent">
          Any service marks, logos, and trademarks (collectively the
          "Trademarks") displayed are registered and/or unregistered Trademarks
          of their respective owners. This site contains affiliate and partner
          links and may receive compensation for referrals or purchases made
          through our links. Subject matter within this website are copyrighted
          property of the owner of this site. This website and its owner is not
          responsible for any photographic or typographical errors.
        </p>
      </div>
      <ModalLinks
        type="footer"
        disclosure={{ DisclosureContent: homeService }}
        config={config}
        params={params}
        typage="upsell"
      />
    </Footer>
  );
};

export default UpsellDisclosure;
