import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InnerHTML from 'dangerously-set-html-content';
import { resizeIframes, coinFlip } from '../../utils/utilityFunctions';
import storage from '../../utils/storage';
import CredibleDisclosure from './CredibleDisclosure';
import './adButlerStyles.css';

const AdButlerContainer = styled(InnerHTML)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--containerBackground);
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 40px;

  br {
    margin-bottom: 0;
  }
  iframe {
    height: auto;
    width: 75%;
  }

  iframe:not([id='placement_274577_0_iframe']) {
    width: 100vw;
    max-width: 900px;
  }

  @media (max-width: 1100px) {
    iframe,
    iframe:not([id='placement_274577_0_iframe']) {
      width: 100%;
    }
  }
`;

const CredibleDisclosureContainer = styled.p`
  color: rgb(118, 118, 118);
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  text-align: center;
  padding: 10px 5px;
  .srOnly {
    flex: 0 0 50px;
    margin-top: auto;
    margin-bottom: 10px;
  }
`;

const coinFlipRes = coinFlip();

const AdButler = ({
  adButlerIds,
  adButlerAcctId = '169214',
  path,
  config = {},
}) => {
  const [html, setHtml] = useState('');
  const [readSuccess, setReadSuccess] = useState(false);

  const uuid = storage('session', 'get', 'uuid');
  useEffect(() => {
    setTimeout(() => {
      setReadSuccess(true);
    }, 2000);
  });

  useEffect(() => {
    const removeErrorGif = () => {
      setTimeout(() => {
        const imgs = document.querySelectorAll('img');
        const source = 'https://servedbyadbutler.com/error/blank.gif';
        if (imgs && imgs.length > 0) {
          Array.prototype.forEach.call(imgs, img => {
            if (img.src === source) {
              img.remove();
            }
          });
        }
      }, 2000);
    };

    (async () => {
      const adButlerScriptArray = await adButlerIds.map((id, index) => {
        return `<!-- ${index} [asyncbeta] -->
        <script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
        <div class="plc${id}"></div>
        <script type="text/javascript">
        var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
        var abkw = window.abkw || '';
        var plc${id} = window.plc${id} || 0;
        (function(){
        var divs = document.querySelectorAll(".plc${id}:not([id])");
        var div = divs[divs.length-1];
        div.id = "placement_${id}_"+plc${id};
        AdButler.ads.push({handler: function(opt){ AdButler.register(${adButlerAcctId}, ${id}, [730,300], 'placement_${id}_'+opt.place, opt); }, opt: { place: plc${id}++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
        })();
        </script>`;
      });

      const adString = await adButlerScriptArray.join('');
      const replaceHttp = await adString.replace(/http:\/\//gi, 'https://');

      await setHtml(replaceHttp);

      await resizeIframes(uuid, config, coinFlipRes);
      await removeErrorGif();
    })();

    // FEDP-3176: TYP Spacing
    (() => {
      // hides the GCR Verbiage or Congratulations from adbutler if the page URL is TEL or USL.
      // not a fan of this but I can't reach outside of an iframe to hide it properly.
      setTimeout(() => {
        const siteURL = window.location.href;
        const gcrVerbiage = document.querySelector('.plc252590');
        const congratsBanner = document.querySelector('.plc252591');
        const isTel = siteURL.indexOf('tel-thankyou') !== -1;
        const isUsl = siteURL.indexOf('usl-thankyou') !== -1;

        if (gcrVerbiage && (isTel || isUsl)) {
          gcrVerbiage.style.display = 'none';
        }
        if (congratsBanner && (isTel || isUsl)) {
          congratsBanner.style.display = 'none';
        }
      }, 2000);
    })();
  }, [adButlerIds, adButlerAcctId, uuid, config]);

  return (
    <div
      className="container showBlocks"
      id="adButlerShowAll"
      style={{ position: 'relative', minHeight: '100vh' }}
    >
      <div id="adbutler">
        <AdButlerContainer id="adbutlerContainer" html={html} />
      </div>
      <CredibleDisclosure />
    </div>
  );
};

AdButler.propTypes = {
  adButlerIds: PropTypes.array,
  adButlerAcctId: PropTypes.string,
  path: PropTypes.string,
};

export default AdButler;
