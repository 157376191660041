import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Spinner from '../Dynamic/Spinner';
import styles from '../../utils/customStyles';
import { doSendEmail } from '../../utils/forms/API/requests';

const customStyles = styles();

const SubmitButton = styled.button`
  ${customStyles.SubmitButton};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: none;
      opacity: 0.5;
      margin-top: 15px;
    `}
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: auto;
  margin-top: 20vh;
  position: relative;

  input {
    margin-top: 5px;
    margin-bottom: 10px;
    height: 40px;
  }
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
`;

const ErrorText = styled.p`
  color: #ee0000;
  font-style: italic;
  text-align: left;
  font-size: 12px;
`;

const ContactUs = ({ handleClose }) => {
  const [formData, setFormData] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
  });
  const [loadingState, setLoadingState] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState('');

  const validateEmail = () => {
    let err;
    if (formData.Email === '') {
      err = 'Please enter an email address.';
    } else if (
      !formData.Email ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.Email)
    ) {
      err = 'Please enter a valid email address';
    }
    setError(err);

    if (!err) {
      setIsDisabled(false);
    }
  };

  const handleContactForm = e => {
    e.preventDefault();
    validateEmail();
    if (formData.Email && !error) {
      doSendEmail(formData);
      setLoadingState(true);
      setTimeout(() => {
        handleClose();
        setLoadingState(false);
      }, 1000);
    }
  };

  const emailOnChange = e => {
    if (e.target.value && !e.target.value.includes('@')) {
      setIsDisabled(true);
    } else if (!e.target.value) {
      setIsDisabled(true);
    }
    validateEmail();
    setFormData({ ...formData, Email: e.target.value });
  };

  useEffect(() => {
    if (!formData.Email) {
      setIsDisabled(true);
    }

    if (error) {
      setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [formData, error]);

  return (
    <ContactForm onSubmit={handleContactForm}>
      {loadingState && <Spinner />}
      <h2>Contact Us</h2>
      <StyledLabel htmlFor="FirstName">
        First Name
        <input
          type="text"
          id="FirstName"
          name="FirstName"
          onChange={e =>
            setFormData({ ...formData, FirstName: e.target.value })
          }
        />
      </StyledLabel>
      <StyledLabel htmlFor="LastName">
        Last Name
        <input
          type="text"
          id="LastName"
          name="LastName"
          onChange={e => setFormData({ ...formData, LastName: e.target.value })}
        />
      </StyledLabel>
      <StyledLabel htmlFor="Email">
        Email Address
        <input
          type="text"
          id="Email"
          name="Email"
          onChange={emailOnChange}
          onBlur={validateEmail}
        />
      </StyledLabel>
      {error && <ErrorText role="alert">{error}</ErrorText>}
      <SubmitButton type="submit" disabled={isDisabled}>
        Send Email
      </SubmitButton>
    </ContactForm>
  );
};

export default ContactUs;
