import React from 'react';
import styled, { keyframes } from 'styled-components';

const Spinner = keyframes`
 {
  0%   { transform: rotate(0deg);   transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}
`;

const SpinnerContainer = styled.div`
  position: absolute;
  height: inherit;
  width: inherit;
`;

const SpinnerIcon = styled.section`
  margin: auto;
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  border: solid 1px lightblue;
  border-top-color: grey;
  border-left-color: lightblue;
  border-radius: 80px;

  animation: ${Spinner} 700ms linear infinite;
`;

const SpinnerComponent = ({ size = '100px', containerStyles }) => (
  <SpinnerContainer style={containerStyles}>
    <SpinnerIcon size={size} />
  </SpinnerContainer>
);

export default SpinnerComponent;
