// Only allowing numbers to be typed into the input field

const numbersOnly = () => {
  const element = document.getElementById('phone-input');

  // Doesn't allow 1 to be the start of the phone number
  const oneRegex = /^1|^\(1/;
  const regexTest = oneRegex.test(element.value);
  if (regexTest) {
    element.value = '';
  }

  const x = element.value
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  element.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
};

const secondaryNumbersOnly = () => {
  const element = document.getElementById('secondary-phone-input');

  // Doesn't allow 1 to be the start of the phone number
  const oneRegex = /^1|^\(1/;
  const regexTest = oneRegex.test(element.value);
  if (regexTest) {
    element.value = '';
  }

  const x = element.value
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  element.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
};

export { numbersOnly, secondaryNumbersOnly };
