import storage from '../storage';
import { sendPixelTracking } from '../forms/API/requests';

const getCakeValues = () => {
  // get the stuff we may need from session storage and put it into js variables we can use
  try {
    let pixelHtml = storage('session', 'get', 'pixelHtml');
    const payability = storage('session', 'get', 'payability');
    const formJSON = storage('local', 'get', 'formData');
    const UUID = storage('session', 'get', 'uuid');
    let throttleId = '';

    if (pixelHtml && formJSON) {
      const formData = JSON.parse(formJSON);
      const tokenObj = {
        '#affid#': formData.affiliate_id || '',
        '#oid#': formData.offer_id || '',
        '#campid#': formData.campaign_id || '',
        '#cid#': formData.cid || '',
        '#s1#': formData.subAffiliate || '',
        '#s2#': formData.ckm_subid_2 || '',
        '#s3#': formData.ckm_subid_3 || '',
        '#s4#': formData.ckm_subid_4 || '',
        '#s5#': formData.ckm_subid_5 || '',
        '#sourceip#': formData.user_ip || '',
        '#utcunixtime#': Math.round(new Date().getTime() / 1000),
        '#sourcetime#': new Date().toISOString(),
      };
      pixelHtml = pixelHtml.replace(
        /#affid#|#oid#|#campid#|#cid#|#s1#|#s2#|#s3#|#s4#|#s5#|#sourceip#|#utcunixtime#|#sourcetime#/gi,
        matched => tokenObj[matched]
      );
    }

    // write in the appropriate affiliate pixels that we got from cake API
    if (payability) {
      const { payable, throttled, throttle_id } = JSON.parse(payability);
      throttleId = throttle_id;

      const pixelData = {
        PixelType: 'Payability',
        PixelData: pixelHtml,
        ThrottleDecisionId: throttleId,
        UUID,
      };
      sendPixelTracking(pixelData);

      if (payable && !throttled) {
        // let iframe;
        // iframe = document.getElementById('script-container');
        // iframe =
        //   iframe.contentWindow ||
        //   iframe.contentDocument.document ||
        //   iframe.contentDocument;
        // iframe.document.open();
        // iframe.document.write(pixelHtml);
        // iframe.document.write('<div id="iframe-body"></div>');
        // iframe.document.close();

        // const iframeBody = iframe.document.getElementById('iframe-body');
        // iframeBody.innerHTML = pixelHtml;

        return pixelHtml;
      }
      return '';
    }
  } catch (error) {
    console.log('Unable to fire payability pixels', error);
  }
};

export default getCakeValues;
