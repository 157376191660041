import React from 'react';

const UpsellHeader = ({ lsData, surveyType, handleSolarYesNo }) => {
  return (
    <div className="eligibility">
      <p className="congrats">
        Congratulations, {lsData.first_name}
        {surveyType !== 'homeservices' && ` ${lsData.last_name}!`} We are
        working to find the best{' '}
        {surveyType !== 'homeservices' ? 'lenders' : 'roofers'} for you in{' '}
        {lsData.city}!
      </p>
      <p className="interestText">
        You also shared an interest in solar panels. To better serve you, please
        answer a few quick questions.
      </p>
      <div className="buttonContainer">
        <button
          type="button"
          className="onlyRoofing"
          onClick={() => handleSolarYesNo(0)}
        >
          {surveyType !== 'homeservices' ? (
            <p>No thanks</p>
          ) : (
            <p>No thanks, I only want roofing</p>
          )}
        </button>
      </div>
    </div>
  );
};

export default UpsellHeader;
