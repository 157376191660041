import React, { useEffect, useState } from 'react';
import { parse } from 'query-string';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useLayoutQueries } from '../../utils/queries';

const ThankyouSeo = ({ jornayaScript = false, tyType, path, config = {} }) => {
  // console.log('🚀 ~ file: ThankyouSeo.js ~ line 8 ~ config', config);
  const [clicks2ConvertUrl, setClicks2ConvertUrl] = useState();
  const Script_Loading_Site_Test = [
    'govhomeprograms.com',
    'mycashoutquiz.com',
    'info.vabenefitsurvey.com',
    'qualifiedsolarsurvey.com',
    'homeroofingsurvey.com',
    'homewindowsurvey.com',
    'downpaymentsurvey.com',
  ];

  let query;

  if (typeof window !== 'undefined') {
    query = parse(window.location.search);
  }

  const { siteImages } = useLayoutQueries();

  const favicon = siteImages.edges.find(image => {
    return image.node.fluid.originalName === 'favicon.png';
  });

  useEffect(() => {
    if (tyType) {
      const tyLowerCase = tyType.toLowerCase();
      if (tyLowerCase.includes('auto')) {
        setClicks2ConvertUrl(
          `https://dashboard.clickstoconvert.com/js/publisher/showListing.js`
        );
      } else if (tyLowerCase.includes('home')) {
        setClicks2ConvertUrl(
          `https://dashboard.clickstoconvert.com/js/publisher/showListing.js`
        );
      }
    }
  }, [tyType, clicks2ConvertUrl]);

  const chatBotTyPages = ['etff-thankyou', 'emoo-thankyou'];

  return (
    <Helmet
      title="Thank You"
      link={[
        {
          rel: 'shortcut icon',
          type: 'image/png',
          href: `${favicon.node.fluid.src}`,
        },
        // {
        //   href: `${googleFontLink}`,
        //   rel: 'stylesheet',
        // },
      ]}
      meta={[
        {
          name: 'referrer',
          content: 'origin',
        },
      ]}
    >
      <script
        async
        src={
          typeof window !== 'undefined' && !window?.gtag && config?.gTag
            ? `https://www.googletagmanager.com/gtag/js?id=${config?.gTag}`
            : ''
        }
      />
      <script>
        {typeof window !== 'undefined' && !window?.gtag && config?.gTag
          ? `window.dataLayer = window.dataLayer || []; function gtag()
          {dataLayer.push(arguments)}
          gtag('js', new Date()); gtag('config', '${config?.gTag}');`
          : ''}
      </script>

      {typeof window !== 'undefined' && (!window?.jtknet || !window?.lincx) && (
        <>
          <script async src="https://offers.printfingertech.net/js/jtknet.js" />
          <script
            async
            src="https://api.lincx.com/load"
            data-manual-render="true"
            data-zone-id="231260"
          />
        </>
      )}

      {path.includes('etff-thankyou') && (
        <script type="text/javascript">
          {`window.BOTSPLASH_APP_ID="6f720fd0-a969-431a-9ae6-0b2dc8e9ac7b";
    (function(){var d=document;var s=d.createElement("script");s.src="https://chatcdn.botsplash.com/x.js";s.async=true;d.getElementsByTagName("head")[0].appendChild(s);})();`}
        </script>
      )}
      {path.includes('emoo-thankyou') && (
        <script type="text/javascript">
          {`window.BOTSPLASH_APP_ID = "92e4f761-80c1-4778-a8a7-6527e78b7dfa";
  (function () { var d = document; var s = d.createElement("script"); s.src="https://chatcdn.botsplash.com/x.js"; s.async = true; d.getElementsByTagName("head")[0].appendChild(s); })();`}
        </script>
      )}

      {jornayaScript && (
        <script type="text/javascript">
          {`function jornayaLeadId(token) {
  console.log('hit jornaya', token);
  sessionStorage.setItem('jornaya_lead', token)
}`}
        </script>
      )}

      {/* // gets defined clicks to convert script to load on certain ty pages */}
      {tyType && <script src={clicks2ConvertUrl} />}
    </Helmet>
  );
};

ThankyouSeo.propTypes = {
  // googleFontLink: PropTypes.string,
  jornayaScript: PropTypes.bool,
  tyType: PropTypes.string,
  path: PropTypes.string,
};

export default ThankyouSeo;
