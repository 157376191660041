import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
import { webHookTrack } from '../../dynamic/webhookTrack';
import sendRequest from '../API/sendRequest';
import Choice from '../../../components/inputs/Choice';
import {
  texasProviders,
  newYorkProviders,
  floridaProviders,
  californiaProviders,
  pennsylvaniaProviders,
  arizonaProviders,
} from '../../../components/inputs/Dropdown/electricDropdownValues';
import { g4EventTracking } from '../../dynamic/g4Tracking';

const StyledErrorMessage = styled.p`
  color: #ee0000;
  text-align: center !important;
  margin-top: 5px;
`;

const StyledProviderButtons = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 10px auto 20px;
  width: 100%;
  div {
    width: 30%;
    > input:checked + label {
      border: 2px solid #02d64d;
      color: #000;
      background: #fff
        url(https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/providerChecked.png)
        20px 50%/20px no-repeat;
      &:focus,
      &:active {
        // border: 2px solid #02d64d;
        outline: 2px solid #02d64d;
        background: #fff
          url(https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/providerChecked.png)
          20px 50%/20px no-repeat;
      }
    }
    @media (max-width: 801px) {
      width: 100%;
    }
  }
  p {
    width: 100%;
  }
  label {
    background: #fff
      url(https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/providerUnchecked.png)
      20px 50%/20px no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: ${props =>
      props.errors ? '1px solid red' : '1px solid #ccc'} !important;
    &:hover,
    &active {
      background: #fff;
      border: 2px solid #02d64d;
      background: #fff
        url(https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/providerChecked.png)
        20px 50%/20px no-repeat;
    }
    &:focus {
      // border: 2px solid #02d64d;
      outline: 2px solid #02d64d;
      background: #fff;
    }
  }
  select,
  input {
    border: ${props =>
      props.errors ? '1px solid red' : '1px solid #ccc'} !important;
  }
  @media (max-width: 801px) {
    padding: 0 20px;
  }
`;

const UpsellProviderButtons = ({
  values,
  isRequired,
  errors,
  setElectricCompany,
  electricCompany,
  lsData,
  setNoLocalStorage,
  envToggle,
  setFieldValue,
  config,
  setErrors,
}) => {
  const [hasStateData, setHasStateData] = useState(true);
  const [staticElectricProviders, setElectricProviders] = useState([]);
  const [showElectricProviders, setShowElectricProviders] = useState(false);

  const stateArray = ['NY', 'TX', 'AZ', 'FL', 'CA', 'PA'];

  // If the user selects other for ElectricCompany render a dropdown else progress through the form
  const handleShowElectricDropDown = (e, key) => {
    const { id } = e.target;
    if (id === 'ShowDropDown') {
      webHookTrack('upsell', { Electric_Provider_Button: key + 1 });

      g4EventTracking('upsell', { Electric_Provider_Button: key + 1 });

      setShowElectricProviders(true);
    } else {
      // eslint-disable-next-line no-lonely-if
      webHookTrack('upsell', { Electric_Provider_Button: key + 1 });

      g4EventTracking('upsell', { Electric_Provider_Button: key + 1 });
    }
    setErrors('ElectricCompany', '');
  };

  const handleClick = () => {
    webHookTrack('Upsell', { ElectricCompany: values.ElectricCompany });

    g4EventTracking('Upsell', { ElectricCompany: values.ElectricCompany });
  };

  const electricDropdown = (state, arr) => {
    let electricProviders = [];
    switch (state) {
      case 'CA':
        setElectricProviders(californiaProviders);
        electricProviders = californiaProviders.map(option => {
          return option.label;
        });
        break;
      case 'TX':
        setElectricProviders(texasProviders);
        electricProviders = texasProviders.map(option => {
          return option.label;
        });
        break;
      case 'PA':
        setElectricProviders(pennsylvaniaProviders);
        electricProviders = pennsylvaniaProviders.map(option => {
          return option.label;
        });
        break;
      case 'NY':
        setElectricProviders(newYorkProviders);
        electricProviders = newYorkProviders.map(option => {
          return option.label;
        });
        break;
      case 'FL':
        setElectricProviders(floridaProviders);
        electricProviders = floridaProviders.map(option => {
          return option.label;
        });
        break;
      case 'AZ':
        setElectricProviders(arizonaProviders);
        electricProviders = arizonaProviders.map(option => {
          return option.label;
        });
        break;
      default:
        electricProviders = [];
    }
    // const providers = removeDupArrItems(electricProviders.concat(arr));
    setHasStateData(false);
    const filteredOptions = arr.filter(
      option => !electricProviders.includes(option)
    );

    setElectricCompany(filteredOptions);
  };

  const electricCompanyMap = electricCompany.map((option, optionIndex) => (
    <option
      value={option}
      key={optionIndex}
      // disabled={option.includes('--') ? true : null}
    >
      {option}
    </option>
  ));

  useEffect(() => {
    // if (lsData.state && stateArray.includes(lsData.state)) {
    // setElectricDropdown(lsData.state);
    try {
      const getStateAPI = `https://external${envToggle}.printfinger.tech:7105/api/CheckRules`;
      const requestData = {
        activity: 'GetElectricalProviderForState',
        state: lsData.state,
        MapName: 'FrontEndSignup',
      };

      const successFunction = res => {
        if (res) {
          const mappedRes = res.Payload.map(val => {
            return val.ProviderName;
          });
          if (lsData.state && stateArray.includes(lsData.state)) {
            electricDropdown(lsData.state, mappedRes);
          } else {
            setHasStateData(false);
            setShowElectricProviders(true);
            setElectricCompany(mappedRes);
          }
        } else {
          setHasStateData(false);
          setShowElectricProviders(true);
        }
      };
      if (lsData.state) {
        sendRequest(requestData, getStateAPI, successFunction);
        setNoLocalStorage(false);
      } else {
        setNoLocalStorage(true);
      }
    } catch (error) {
      setNoLocalStorage(true);
    }

    // eslint-disable-next-line
  }, [lsData]);

  useEffect(() => {
    if (
      stateArray.includes(lsData.state) &&
      values.ElectricCompany === 'ShowDropDown'
    ) {
      setFieldValue('ElectricCompany', '');
    }
    // eslint-disable-next-line
  }, [values.ElectricCompany]);

  return (
    <StyledProviderButtons errors={errors.ElectricCompany}>
      <p className="solar-label">Who is your electricity provider?</p>
      {!showElectricProviders &&
        staticElectricProviders.map((option, key) => (
          <>
            <Choice
              name="ElectricCompany"
              key={key}
              index={key}
              option={option}
              value={option.value}
              onClick={e => handleShowElectricDropDown(e, key)}
              values={values}
              params={{}}
              config={config}
              setFieldValue={setFieldValue}
              validate={isRequired('Please select your electric provider')}
            />
          </>
        ))}

      {!hasStateData && showElectricProviders ? (
        <>
          <Field
            component="select"
            name="ElectricCompany"
            validate={isRequired('Please select your electric provider')}
            className="solar-individual-question"
            onBlur={handleClick}
          >
            <option value="" disabled>
              Select One:
            </option>
            {electricCompanyMap}
          </Field>
        </>
      ) : (
        <>
          {showElectricProviders && (
            <div>
              <Field
                name="ElectricCompany"
                type="text"
                value={values.ElectricCompany}
                validate={isRequired('Electric Company is missing')}
                className="solar-individual-question"
                onInput={handleClick}
              />
            </div>
          )}
        </>
      )}
      {errors.ElectricCompany && (
        <StyledErrorMessage className="styled-error">
          {errors.ElectricCompany}
        </StyledErrorMessage>
      )}
    </StyledProviderButtons>
  );
};

export default UpsellProviderButtons;
