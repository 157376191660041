import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Field } from 'formik';
import { webHookTrack } from '../../dynamic/webhookTrack';
import { g4EventTracking } from '../../dynamic/g4Tracking';

const StyledErrorMsg = styled.p`
  color: #ee0000;
  margin-top: 5px;
`;

const CreditInputContainer = styled.div`
  select {
    border: ${props =>
      props.errors ? '1px solid red' : '1px solid #ccc'} !important;
  }
`;

const UpsellCreditInput = ({
  values,
  isRequired,
  errors,
  setErrors,
  config,
}) => {
  const handleBlur = () => {
    if (values.credit_rating) {
      setErrors('credit_rating', '');
    }

    webHookTrack('upsell', { credit_rating: values.credit_rating });

    g4EventTracking('upsell', { credit_rating: values.credit_rating });
  };
  return (
    <CreditInputContainer className="question" errors={errors.credit_rating}>
      <p className="solar-label">
        How is your credit? <span>(most people have 'Good' credit)</span>
      </p>
      <Field
        component="select"
        name="credit_rating"
        validate={isRequired('Please select your credit rating')}
        className="solar-individual-question"
        onBlur={handleBlur}
        id="creditRating"
      >
        <option value="" disabled>
          Select One:
        </option>
        <option value="excellent">Excellent (720 and above)</option>
        <option value="good">Good (660-719)</option>
        <option value="average">Average (620-659)</option>
        <option value="fair">Below average (580-619)</option>
        <option value="poor">Poor (579 and below)</option>
      </Field>
      {errors.credit_rating && (
        <StyledErrorMsg>{errors.credit_rating}</StyledErrorMsg>
      )}
    </CreditInputContainer>
  );
};

export default UpsellCreditInput;
