import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import styled from 'styled-components';
import withThankyouWrapper from '../components/ThankyouPages/withThankyouWrapper';
import DynamicThankyou from '../components/ThankyouPages/DynamicThankyou';
import storage from '../utils/storage';
import adbutlerZones from '../utils/adbutlerZones';
import { webHookTrack } from '../utils/dynamic/webhookTrack';
import { g4EventTracking } from '../utils/dynamic/g4Tracking';

const Container = styled.div`
  margin: auto;
  padding: 15px;
  width: 100%;
  max-width: 1200px;
`;

// fake vwo functions to allow vwo to fail on this silently w/o blowing up Sentry
try {
  window.alterConfig = () => {
    // fail silently
  };
  window.alterQuestions = () => {
    // fail silently
  };
  window.addQuestion = () => {
    // fail silently
  };
  window.removeQuestion = () => {
    // fail silently
  };
  window.changeFromSlider = () => {
    // fail silently
  };
} catch (error) {
  // fail silently
}

// let params = {};

// if (typeof window !== 'undefined') {
//   const query = window.location.search;
//   if (query) params = parse(query);
// }

const Back = ({ config, path }) => {
  const [adIds, setAdIds] = useState([]);
  const [parsedGlobalConfig, setParsedGlobalConfig] = useState({});
  const [params, setParams] = useState({});
  useEffect(() => {
    const query = parse(window.location.search);
    setParams(query);
    setTimeout(() => {
      const globalConfig = storage('session', 'get', 'globalConfig');
      const scData = storage('session', 'get', 'scData');
      if (globalConfig) {
        setParsedGlobalConfig(JSON.parse(globalConfig));
      }
      const adObject = adbutlerZones('back', false, params, config);

      if (scData) {
        const parsedScData = JSON.parse(scData);
        const siteVert = parsedScData[0].siteVertical;
        const vert = siteVert.split('_')[1];

        if (adObject[vert]) {
          setAdIds(adObject[vert]);
        } else {
          setAdIds(adObject.default);
        }
      } else {
        console.log('render default zones');
        setAdIds(adObject.default);
      }
    }, 500);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const furthestQuestion = storage('session', 'get', 'furthestQuestion');
    setTimeout(() => {
      if (furthestQuestion && window.ga)
        webHookTrack('back_furthest_question', {
          question: furthestQuestion,
        });

      g4EventTracking('back_furthest_question', {
        question: furthestQuestion,
      });
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <DynamicThankyou
        adButlerIds={adIds}
        adButlerAcctId="169214"
        params={params}
        config={config}
        path={path}
      />
    </Container>
  );
};

Back.propTypes = {
  config: PropTypes.object,
  path: PropTypes.string,
};

export default withThankyouWrapper(Back);
