import React, { useEffect, useState, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../utils/customStyles';
import Modal from './Modal.js';
import ContactUs from './ContactUs';
import InactiveModal from './InactiveModal';
import { useLayoutQueries } from '../../utils/queries';
import { activateActivityTracker } from '../../utils/inactivityListener';
import GetAdDisclosure from './AdDisclosureFetch';
import AccessibilityStatement from '../../../data/defaultText/accessibilityStatement';
import { StoreContext } from '../StoreContext';

const customStyles = styles();

export const ModalInformation = styled.div`
  ${customStyles.ModalInformation}
  iframe {
    height: -webkit-fill-available;
  }

  a {
    cursor: pointer;
  }
`;

const ButtonContainer = styled.section`
  ${customStyles.ModalButtonContainer}
`;

const ModalButton = styled.button`
  ${customStyles.ModalButton}
  background-color: transparent;
`;

const Paragraph = styled.p`
  margin-top: 0 !important;
  ${customStyles.CheckboxParagraph}
`;

const StyledSpan = styled.span`
  ${customStyles.ModalSpan}
`;

const ModalLinks = ({ type, disclosure, config, params, typage = '' }) => {
  const {
    surveyName,
    rmpSite,
    smsDisclosure,
    advertisingDisclosure,
    inactiveModal,
    scData,
    doSolarUpsell,
    upsideInsurance,
  } = config;
  const [displayModal, setDisplay] = useState(false);
  const [modalType, setModal] = useState('');
  const { inactiveModal: contextInactive } = useContext(StoreContext);

  const {
    interestRates,
    rmpDns,
    rmpPrivacyText,
    rmpTosText,
    rmpLicensure,
    vaFAQ,
    thankyouRmpTos,
    thankyouRmpPrivacy,
    thankyouRmpDns,
    thankyouRmpLicensure,
    smsToC,
    smsPP,
    thankyouGhPrivacy,
    thankyouGhTos,
  } = useLayoutQueries();
  const handleClose = e => {
    e.stopPropagation();
    const { id } = e.target;
    if (id === 'modal' || id === 'xButton') {
      e.preventDefault();

      setTimeout(() => {
        setDisplay(false);
      }, 0);
    }
  };

  const openModal = async val => {
    await setModal(val);
    await setDisplay(true);
  };
  useEffect(() => {
    if (
      modalType === 'Do Not Sell My Personal Information' ||
      modalType === 'Terms of Use'
    ) {
      document.addEventListener('click', async e => {
        if (e.target && e.target.id === 'privacyModalOpen') {
          await setDisplay(false);
          await openModal('Privacy Policy');
        }
      });
      document.addEventListener('keydown', async e => {
        if (
          e.key === 'Enter' &&
          e.target &&
          e.target.id === 'privacyModalOpen'
        ) {
          await setDisplay(false);
          await openModal('Privacy Policy');
        }
      });
    }
    if (modalType === 'Privacy Policy') {
      document.addEventListener('click', async e => {
        if (e.target && e.target.id === 'doNotSellModalOpen') {
          await setDisplay(false);
          await openModal('Do Not Sell My Personal Information');
        }
      });
      document.addEventListener('keydown', async e => {
        if (
          e.key === 'Enter' &&
          e.target &&
          e.target.id === 'doNotSellModalOpen'
        ) {
          await setDisplay(false);
          await openModal('Do Not Sell My Personal Information');
        }
      });
    }
  }, [modalType]);

  const [isInactive, setIsInactive] = useState(false);
  useEffect(() => {
    if (config.inactiveModal || contextInactive) {
      activateActivityTracker(setIsInactive);
    }
  }, [config, contextInactive]);

  useEffect(() => {
    if (isInactive) {
      openModal('Inactive Modal');
    }
  }, [isInactive]);

  const rmpContact = [
    'rateselectsurvey.com',
    'homerelief.ratemarketplace.com',
    'qualify.ratemarketplace.com',
    'enhancedrefi.ratemarketplace.com',
    'cashout.ratemarketplace.com',
  ];
  let showRmpContact = false;
  if (rmpContact.includes(surveyName)) {
    showRmpContact = true;
  }

  const modalValues = [
    'Terms of Use',
    'Privacy Policy',
    'Do Not Sell My Personal Information',
    'Accessibility Statement',
  ];
  if (advertisingDisclosure) {
    modalValues.push('Advertising Disclosure');
  }

  const mortgageVerticals = ['harp', 'dpa', 'fha', 'va'];

  if (
    (rmpSite && mortgageVerticals.includes(scData.siteVertical)) ||
    upsideInsurance
  ) {
    modalValues.push('Licensure');
  }
  if (smsDisclosure) {
    modalValues.push('SMS Short Code Terms and Conditions');
    modalValues.push('SMS Short Code Privacy Policy');
  }
  if (params?.fb === '1') {
    modalValues.push('Contact Us');
  }

  const returnDisclosure = () => {
    if (
      surveyName === 'rateselectsurvey.com' ||
      surveyName === 'homerelief.ratemarketplace.com' ||
      surveyName === 'qualify.ratemarketplace.com' ||
      surveyName === 'enhancedrefi.ratemarketplace.com'
    ) {
      const modalHash = {
        'Terms of Use': rmpTosText,
        'Privacy Policy': rmpPrivacyText,
        'Do Not Sell My Personal Information': thankyouRmpDns?.childPlainText,
        'Advertising Disclosure': interestRates?.childPlainText,
        // eslint-disable-next-line prettier/prettier
        Licensure: thankyouRmpLicensure?.childPlainText,
      };

      return modalHash[modalType]?.content;
    }

    // const modalHash = {
    //   'Terms of Use': disclosure.termsOfUse,
    //   'Privacy Policy': disclosure.privatePolicy,
    //   'Do Not Sell My Personal Information': disclosure.doNotSellLink,
    // };
    let currentDisclosure = '';

    if (modalType === 'Terms of Use') {
      if (doSolarUpsell && typage === 'upsell') {
        currentDisclosure = thankyouGhTos?.childPlainText?.content;
      } else {
        currentDisclosure =
          disclosure.termsOfUse || thankyouRmpTos?.childPlainText?.content;
      }
    } else if (modalType === 'Privacy Policy') {
      if (doSolarUpsell && typage) {
        currentDisclosure = thankyouGhPrivacy?.childPlainText?.content;
      } else {
        currentDisclosure =
          disclosure.privatePolicy ||
          thankyouRmpPrivacy?.childPlainText?.content;
      }
    } else if (modalType === 'VA FAQ') {
      currentDisclosure = vaFAQ?.childPlainText?.content;
    } else if (modalType === 'Do Not Sell My Personal Information') {
      currentDisclosure =
        disclosure.doNotSellLink || thankyouRmpDns?.childPlainText?.content;
    } else if (modalType === 'Licensure') {
      currentDisclosure =
        disclosure.Licensure || thankyouRmpLicensure?.childPlainText?.content;
    } else if (modalType === 'SMS Short Code Terms and Conditions') {
      currentDisclosure = smsToC?.childPlainText?.content;
    } else if (modalType === 'SMS Short Code Privacy Policy') {
      currentDisclosure = smsPP?.childPlainText?.content;
    } else if (modalType === 'Advertising Disclosure') {
      currentDisclosure = interestRates?.childPlainText?.content;
    }
    return currentDisclosure;
  };

  const buttonMap = modalValues.map((button, buttonIndex) => {
    const newSpan = button;
    const spanClass = newSpan.replace(/\s/g, '');

    return (
      <div key={buttonIndex}>
        <ModalButton
          key={buttonIndex}
          type="submit"
          onClick={() => openModal(button)}
          className="footer-modal-button"
          style={button === 'submitModal' ? { display: 'none' } : {}}
        >
          {button}
        </ModalButton>
        {buttonIndex !== modalValues.length - 1 && (
          <span className={spanClass}>|</span>
        )}
      </div>
    );
  });

  let modalLink;

  switch (type) {
    case 'footer':
      modalLink = <ButtonContainer>{buttonMap}</ButtonContainer>;
      break;
    case 'privacy-checkbox':
      modalLink = (
        <Paragraph>
          I agree to the{' '}
          <StyledSpan onClick={() => openModal('Privacy Policy')}>
            privacy policy
          </StyledSpan>{' '}
          within this site.
        </Paragraph>
      );
      break;
    case 'email-checkbox':
      modalLink = (
        <Paragraph data-testid="email-checkbox">
          I certify that this is a valid email address to reach me and I agree
          to the{' '}
          <StyledSpan onClick={() => openModal('Privacy Policy')}>
            privacy policy
          </StyledSpan>{' '}
          and{' '}
          <StyledSpan onClick={() => openModal('Terms of Use')}>
            terms and conditions.
          </StyledSpan>
        </Paragraph>
      );
      break;
    case 'va-faq':
      modalLink = (
        <Paragraph className="faqLink" onClick={() => openModal('VA FAQ')}>
          FAQ about VA Home Loan Benefits
        </Paragraph>
      );
      break;
    default:
      return null;
  }
  let modalContent;
  if (modalType === 'Contact Us') {
    modalContent = <ContactUs handleClose={() => setDisplay(false)} />;
  } else if (modalType === 'Advertising Disclosure') {
    modalContent = <GetAdDisclosure />;
  } else if (modalType === 'Accessibility Statement') {
    modalContent = (
      <AccessibilityStatement
        advertiserId={disclosure.AdvertiserId}
        isRmp={showRmpContact}
      />
    );
  } else if (modalType === 'Inactive Modal') {
    modalContent = <InactiveModal />;
  } else {
    modalContent = (
      <ModalInformation
        dangerouslySetInnerHTML={{
          __html: returnDisclosure(),
        }}
      />
    );
  }

  return (
    <Fragment>
      {modalLink}
      <Modal
        handleClose={handleClose}
        displayModal={displayModal}
        type={modalType}
      >
        {modalContent}
      </Modal>
    </Fragment>
  );
};

ModalLinks.propTypes = {
  config: PropTypes.shape({
    surveyName: PropTypes.string,
  }),
  disclosure: PropTypes.object,
  type: PropTypes.string,
};

export default ModalLinks;
