import React from 'react';
import styled from 'styled-components';
import Phone from '../../images/phone';

const InactiveContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.section`
  font-size: 24px;
  text-align: center;
  border-bottom: 1px solid #8c8c8c;
  width: calc(100% + 6vw);
  margin-left: -3vw;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;
const ModalBody = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;

  a,
  p {
    font-size: 24px;
  }
  a {
    color: #376f9a;
  }

  section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    svg {
      margin-right: 10px;
    }
  }
`;

const InactiveModal = () => {
  return (
    <InactiveContainer>
      <ModalHeader>Inactive Session</ModalHeader>
      <ModalBody>
        <p>Speak with a licensed insurance agent.</p>
        <section>
          <Phone />
          <a href="tel:18665202119">1 (866) 520-2119</a>
        </section>
      </ModalBody>
    </InactiveContainer>
  );
};

export default InactiveModal;
