import React from 'react';
import QssLogo from '../../../images/qss-alt-logo.png';

const UpsellLogo = ({ upsellType = '' }) => {
  const displayLogo = () => {
    switch (upsellType) {
      case 'window':
        return (
          <img
            src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/hws_logo_redux.jpg"
            alt="HWS logo"
          />
        );
      case 'home':
        return (
          <img
            src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/logo.png"
            alt="Insurance Specialists"
          />
        );
      default:
        return <img src={QssLogo} alt="QSS logo" />;
    }
  };
  return <div className="imgContainer">{displayLogo()}</div>;
};

export default UpsellLogo;
