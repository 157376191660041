import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InnerHTML from 'dangerously-set-html-content';
import CredibleDisclosure from './CredibleDisclosure';
import { resizeIframes, coinFlip } from '../../utils/utilityFunctions';
import storage from '../../utils/storage';

const AdButlerContainer = styled(InnerHTML)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 900px;
  text-align: center;

  #sponsored-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
  }

  br {
    margin-bottom: 0;
  }
  iframe {
    height: auto;
    width: 100%;
    margin: 0;
  }

  /* iframe:not([id='placement_274577_0_iframe']) {
    width: 100vw;
    max-width: 900px;
  } */

  .sponsored {
    max-width: 100%;
    flex: 1 1 100%;
    margin-bottom: 20px;
    text-align: center;
  }

  .sponsored::after {
    content: '';
    width: 100%;
  }

  .offer1 {
    flex: 1 1 48%;
    text-align: center;
  }

  .offer2 {
    flex: 1 1 48%;
    text-align: center;
  }

  @media screen and (max-width: 800px) {
    .offer1,
    .offer2,
    .sponsored {
      flex: 1 1 100%;
    }
  }
`;

const coinFlipRes = coinFlip();

const AdButlerTest = ({
  adButlerIds,
  adButlerAcctId = '169214',
  tyType,
  config = {},
  path,
}) => {
  const [html, setHtml] = useState('');
  const uuid = storage('session', 'get', 'uuid');

  const sponsored = ['473608'];
  const offer1 = ['473609'];
  const offer2 = ['473610'];
  // const areTestZones = adButlerIds.some(r => sponsored.includes(r));

  useEffect(() => {
    const removeErrorGif = () => {
      setTimeout(() => {
        const imgs = document.querySelectorAll('img');
        const source = 'https://servedbyadbutler.com/error/blank.gif';
        if (imgs && imgs.length > 0) {
          Array.prototype.forEach.call(imgs, img => {
            if (img.src === source) {
              img.remove();
            }
          });
        }
      }, 2000);
    };

    (async () => {
      const adButlerScriptArray = await adButlerIds.map((id, index) => {
        return `
        ${sponsored.includes(id) ? '<div id="sponsored-container">' : ''}
        ${sponsored.includes(id) ? '<div class="sponsored">' : ''}
        ${offer1.includes(id) ? '<div class="offer1">' : ''}
        ${offer2.includes(id) ? '<div class="offer2">' : ''}
        <!-- ${index} [asyncbeta] -->
        <script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
        <div class="plc${id}"></div>
        <script type="text/javascript">
        var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
        var abkw = window.abkw || '';
        var plc${id} = window.plc${id} || 0;
        (function(){
        var divs = document.querySelectorAll(".plc${id}:not([id])");
        var div = divs[divs.length-1];
        div.id = "placement_${id}_"+plc${id};
        AdButler.ads.push({handler: function(opt){ AdButler.register(${adButlerAcctId}, ${id}, [730,300], 'placement_${id}_'+opt.place, opt); }, opt: { place: plc${id}++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
        })();
        </script>
        ${sponsored.includes(id) ? '</div>' : ''}
        ${offer1.includes(id) ? '</div>' : ''} 
        ${offer2.includes(id) ? '</div>' : ''} 
        ${offer2.includes(id) ? '</div>' : ''} 
        `;
      });

      const adString = await adButlerScriptArray.join('');
      const replaceHttp = await adString.replace(/http:\/\//gi, 'https://');

      await setHtml(replaceHttp);
      await resizeIframes(uuid, config, coinFlipRes);
      await removeErrorGif();
    })();
  }, [
    adButlerIds,
    adButlerAcctId,
    tyType,
    sponsored,
    offer1,
    offer2,
    uuid,
    config,
  ]);

  return (
    <div
      className="container showBlocks"
      id="adButlerShowAll"
      style={{ position: 'relative', minHeight: '100vh' }}
    >
      <div id="adbutler">
        <AdButlerContainer id="adbutlerContainer" html={html} />
      </div>
      <CredibleDisclosure />
    </div>
  );
};

AdButlerTest.propTypes = {
  adButlerIds: PropTypes.array,
  adButlerAcctId: PropTypes.string,
  tyType: PropTypes.string,
  path: PropTypes.string,
};

export default AdButlerTest;
