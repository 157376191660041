import React from 'react';
import { Field, Formik, ErrorMessage } from 'formik';
import styled, { keyframes } from 'styled-components';
import LittleShade from '../../../images/LittleShade.png';
import LotShade from '../../../images/LotShade.png';
import NoShade from '../../../images/NoShade.png';
import Uncertain from '../../../images/Uncertain.png';
import { shadeField } from '../../../components/inputs/Dropdown/upsellValues';
import { webHookTrack } from '../../dynamic/webhookTrack';
import { g4EventTracking } from '../../dynamic/g4Tracking';

const ChoiceContainer = styled.label`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  white-space: pre-line;
  align-items: flex-end;
  background: url(${NoShade}) 50% 25%/100px no-repeat;
  box-shadow: 2px 2px 4px #ababab;
  border: 1px solid #ababab;
  width: 100%;
  height: 150px;
  &:hover {
    border: 2px solid #02d64d;
  }
  p {
    text-align: center;
    margin-bottom: 20px;
  }
  &#Shade-0 {
    background: url(${NoShade}) 50% 25%/100px no-repeat;
  }
  &#Shade-1 {
    background: url(${LittleShade}) 50% 25%/100px no-repeat;
  }
  &#Shade-2 {
    background: url(${LotShade}) 50% 25%/100px no-repeat;
  }
  &#Shade-3 {
    background: url(${Uncertain}) 50% 25%/100px no-repeat;
  }
`;
const RadioField = styled(Field)`
  display: none;
`;

const StyledErrorMsg = styled(ErrorMessage)`
  color: #ee0000;
`;

const UpsellShadeInput = ({ config }) => {
  const handleClick = option => {
    webHookTrack('upsell', { Shade: option.value });

    g4EventTracking('upsell', { Shade: option.value });
  };
  return (
    <div className="question">
      <p className="solar-label">
        How sunny is your roof area? <span>(most homes have full sun)</span>
      </p>
      {shadeField.map((option, key) => (
        <div id={`Shade-${key}`} className="choiceContainer" key={key}>
          <RadioField
            component="input"
            type="radio"
            name="Shade"
            value={option.value}
            id={option.value}
          />
          <ChoiceContainer
            htmlFor={option.value}
            id={`Shade-${key}`}
            name="Shade"
            className={option.label}
            onClick={() => handleClick(option)}
          >
            <p>{option.label}</p>
          </ChoiceContainer>
        </div>
      ))}
      <StyledErrorMsg name="shade" component="div" className="styled-error" />
    </div>
  );
};

export default UpsellShadeInput;
