import React, { useState, useEffect } from 'react';
import { Field, ErrorMessage } from 'formik';
import styled, { keyframes } from 'styled-components';
import { webHookTrack } from '../../dynamic/webhookTrack';
import { removeDupArrItems } from '../../utilityFunctions';
import sendRequest from '../API/sendRequest';
import {
  texasProviders,
  newYorkProviders,
  pennsylvaniaProviders,
  floridaProviders,
  californiaProviders,
  arizonaProviders,
  shadeField,
} from '../../../components/inputs/Dropdown/upsellValues';
import { g4EventTracking } from '../../dynamic/g4Tracking';

const StyledErrorMsg = styled(ErrorMessage)`
  color: #ee0000;
`;

const StyledErrorMessage = styled.p`
  color: #ee0000;
  margin-top: 5px;
`;

const EletricProviderContainer = styled.div`
  select,
  input {
    border: ${props =>
      props.errors ? '1px solid red' : '1px solid #ccc'} !important;
  }
`;

const UpsellElectricProvider = ({
  values,
  isRequired,
  errors,
  setElectricCompany,
  electricCompany,
  lsData,
  setNoLocalStorage,
  envToggle,
  config,
}) => {
  const [hasStateData, setHasStateData] = useState(true);

  const handleClick = () => {
    webHookTrack('upsell', { ElectricCompany: values.ElectricCompany });

    g4EventTracking('upsell', { ElectricCompany: values.ElectricCompany });
  };

  const electricDropdown = (state, arr) => {
    let electricProviders = [];
    switch (state) {
      case 'CA':
        electricProviders = californiaProviders;
        break;
      case 'TX':
        electricProviders = texasProviders;
        break;
      case 'AZ':
        electricProviders = arizonaProviders;
        break;
      case 'NY':
        electricProviders = newYorkProviders;
        break;
      case 'FL':
        electricProviders = floridaProviders;
        break;
      case 'PA':
        electricProviders = pennsylvaniaProviders;
        break;
      default:
        electricProviders = [];
    }
    const providers = removeDupArrItems(electricProviders.concat(arr));
    setHasStateData(false);
    setElectricCompany(providers);
  };

  const electricCompanyMap = electricCompany.map((option, optionIndex) => (
    <option
      value={option}
      key={optionIndex}
      disabled={option.includes('--') ? true : null}
    >
      {option}
    </option>
  ));

  useEffect(() => {
    const stateArray = ['NY', 'TX', 'AZ', 'FL', 'CA', 'PA'];

    // if (lsData.state && stateArray.includes(lsData.state)) {
    // setElectricDropdown(lsData.state);
    try {
      const getStateAPI = `https://external${envToggle}.printfinger.tech:7105/api/CheckRules`;
      const requestData = {
        activity: 'GetElectricalProviderForState',
        state: lsData.state,
        MapName: 'FrontEndSignup',
      };

      const successFunction = res => {
        if (res) {
          const mappedRes = res.Payload.map(val => {
            return val.ProviderName;
          });
          if (lsData.state && stateArray.includes(lsData.state)) {
            electricDropdown(lsData.state, mappedRes);
          } else {
            setHasStateData(false);
            setElectricCompany(mappedRes);
          }
        }
      };
      if (lsData.state) {
        sendRequest(requestData, getStateAPI, successFunction);
        setNoLocalStorage(false);
      } else {
        setNoLocalStorage(true);
      }
    } catch (error) {
      setNoLocalStorage(true);
    }

    // eslint-disable-next-line
  }, [lsData]);

  return (
    <EletricProviderContainer
      errors={errors.ElectricCompany}
      className="question"
    >
      <p className="solar-label">Who is your electricity provider?</p>
      {hasStateData ? (
        <>
          <Field
            name="options"
            type="text"
            value={values.ElectricCompany}
            validate={isRequired('Electric Company is missing')}
            className="solar-individual-question"
            onInput={handleClick}
          />
        </>
      ) : (
        <>
          <Field
            component="select"
            name="ElectricCompany"
            validate={isRequired('Please select your electric provider')}
            className="solar-individual-question"
            onBlur={handleClick}
          >
            <option value="" disabled>
              Select One:
            </option>
            {electricCompanyMap}
          </Field>
        </>
      )}
      {errors.ElectricCompany && (
        <StyledErrorMessage className="styled-error">
          {errors.ElectricCompany}
        </StyledErrorMessage>
      )}
    </EletricProviderContainer>
  );
};

export default UpsellElectricProvider;
