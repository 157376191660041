import React from 'react';

const Phone = ({ color = '#000' }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.91304 8.76573L7.6087 6.85683C7.78261 6.42299 7.82609 5.94577 7.73913 5.51193L6.6087 1L1 1.8243C1.30435 6.81345 3.3913 11.4989 6.91304 15.0564C10.5217 18.6573 15.3043 20.7397 20.3913 21L21 15.0998L16.8261 14.1453C16.3478 14.0586 15.8696 14.102 15.4348 14.3189L13.3478 15.2299C12.0435 14.449 10.8261 13.4946 9.73913 12.41C8.6087 11.282 7.69565 10.0672 6.91304 8.76573Z"
      stroke={color}
      strokeMiterlimit="10"
    />
  </svg>
);

export default Phone;
