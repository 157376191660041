import sendRequest from './sendRequest';
import storage from '../../storage';

const sendClickData = async (
  config,
  tempParams,
  parsed,
  page,
  setExperimentID,
  typTest,
  path = ''
) => {
  const { envToggle = '', uuid, user_ip, offer_id } = tempParams;
  const { cpid, s1 } = parsed;

  const setURLSearchParam = (key, v) => {
    const urlString = new URL(window.location.href);
    urlString.searchParams.set(key, v);
    window.history.pushState({ path: urlString.href }, '', urlString.href);
  };

  const url = `https://external${envToggle}.printfinger.tech:7105/api/CheckRules`;

  const testVariation =
    parsed?.version ||
    parsed?.typversion ||
    window?.jtkResults?.result?.challenger;

  if (testVariation) {
    const experiment =
      window?.jtkResults?.ExperimentId ||
      tempParams?.expid ||
      tempParams?.typexpid;

    if (!path) {
      setExperimentID(experiment);
    }

    const clickData = {
      activity: 'ABTesting',
      UUID: uuid,
      CkmOfferid: offer_id,
      CkmCampaignId: +cpid || '',
      SiteUrl:
        typeof window !== 'undefined'
          ? window.location.href
          : config.scData.siteName,
      IPAddress: user_ip,
      UserAgent: navigator.userAgent,
      TestVariation: +testVariation,
      TYPTest: typTest,
      ExperimentId: experiment,
      CkmSubId1: s1 || '',
    };

    const successFunction = async response => {
      if (response) {
        console.log('Successful');
      }
    };

    const notAcceptedValues = [undefined, null, 'undefined', 'null', ''];
    if (!notAcceptedValues.includes(testVariation)) {
      storage(
        'session',
        'set',
        `${page ? 'typVwoVariation' : 'vwoVariation'}`,
        testVariation
      );
      sendRequest(clickData, url, successFunction, 'POST');
    }

    // update url params with experiment id and its value for the Matrix only down funnel
    if (page && experiment) {
      setURLSearchParam(experiment, testVariation);
    }
  }
};

export default sendClickData;
