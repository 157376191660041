import React from 'react';
import styled from 'styled-components';
import checkMobile from '../../dynamic/checkMobile';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0;
  .congrats {
    font-size: 20px;
    font-weight: 500;
  }
  @media (max-width: 801px) {
    .congrats {
      font-size: 18px;
      font-weight: 500;
    }
    p {
      text-align: center;
    }
  }
`;

const NoButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    background: transparent;
    border: none;
    color: #787676;
    margin: 0;
    height: auto;
    width: auto;
    border-bottom: 1px solid #787676;
    border-radius: 0;
  }
  p {
    margin-bottom: 0;
  }
`;

const UpsellHeader = ({ formData, handleSolarYesNo, upsellType = '' }) => {
  const isMobile = checkMobile('(max-width: 800px)');
  return (
    <HeaderContainer className="eligibility">
      {isMobile ? (
        <p className="congrats">
          Congratulations, {formData.first_name} {formData.last_name}!
        </p>
      ) : (
        <p className="congrats">
          Congratulations, {formData.first_name} {formData.last_name}! We are
          working to find the best lenders for you in {formData.city}!
        </p>
      )}

      <p className="interestText">
        You Indicated an interest in{' '}
        {upsellType === 'home' ? 'home insurance' : 'windows'} for your home.
        You qualify for a FREE quote!
      </p>
      <NoButtonContainer className="buttonContainer">
        <button
          type="button"
          className="onlyRoofing"
          onClick={() => handleSolarYesNo(0)}
        >
          <p>No thanks, I just want to see my lenders</p>
        </button>
      </NoButtonContainer>
    </HeaderContainer>
  );
};

export default UpsellHeader;
